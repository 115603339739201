import { columnActionTypes } from "./column-picker-actions";
import { createNamedWrapperReducer } from "../../../services/redux-builder";

const updateObject = (oldObject, newValues) => {
    return Object.assign({}, oldObject, newValues);
}

const reducer = (state: any = {}, action) => {

    switch (action.type) {
        case columnActionTypes.COLUMN_PICKER_TOGGLE: {
            return updateObject(state, { isPickerCollapsed: !state.isPickerCollapsed });
        }
        case columnActionTypes.COLUMN_PICKER_CLOSE: {
            return updateObject(state, { isPickerCollapsed: true });
        }
        case columnActionTypes.COLUMN_PICKER_COLUMN_VISIBLE_UPDATE: {
            let newColumns: any = [];
            state.columnPickerColumns.forEach((col) => {
                if (col.property === action.column.property) {
                    col.visible = action.column.visible;
                }
                if (!col.visible) {
                    col.showIndex = 999;
                }
                newColumns.push(col);
            })
            return updateObject(state, { columnPickerColumns: newColumns });
        }
        case columnActionTypes.COLUMN_PICKER_COLUMN_ORDER_UPDATE: {

            let target = action.propColumns.filter(function (obj) {
                return obj.label === action.target;
            })[0].showIndex;

            let source = action.propColumns.filter(function (obj) {
                return obj.label === action.source;
            })[0].showIndex;

            if (action.stateColumns[target].canRearrange && action.stateColumns[source].canRearrange) {
                let newColumns = action.propColumns.sort(function (a, b) {
                    return parseInt(a.showIndex) - parseInt(b.showIndex);
                });
                newColumns[source].showIndex = target;

                if (target > source) {
                    for (var i = target; i > source; i--) {
                        newColumns[i].showIndex = i - 1;
                    }
                }
                else {
                    for (i = target; i < source; i++) {
                        newColumns[i].showIndex = i + 1;
                    }
                }

                return updateObject(state, { columnPickerColumns: newColumns });                
            }
            return state;
        }
        case columnActionTypes.COLUMN_PICKER_REFRESH: {
            let newColumns: any = [];
            newColumns = JSON.parse(JSON.stringify(action.columns));

            newColumns.sort(function (a, b) {
                return parseInt(a.showIndex) - parseInt(b.showIndex);
            });

            return updateObject(state, { columnPickerColumns: newColumns });
        }
        default:
            return state;
    }

}


export const columnPickerReducer = (filterName) => {

    return createNamedWrapperReducer(reducer, filterName);
}
