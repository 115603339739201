import axios from './handler';

export const getDocketQueues = (payload) => {
    return axios.post("/home/docket-summary", payload);
}

export const getDocketEventTypes = (payload) => {
    return axios.post("/home/event-types", payload);
}

export const getClaimQueues = (payload) => {
    return axios.post("/home/claim-summary", payload);
}

export const getClaimEventTypes= (payload) => {
    return axios.post("/home/event-types", payload);
}

export const getFavorites = (payload) => {
    return axios.get("/home/favorites", payload);
}

export const getNotificationQueues = (payload) => {
    return axios.post("/home/notification-summary", payload);
}

export const getPOCDashboard = (payload) => {
    return axios.get("/home/poc-dashboard", payload);
}

const legalConfig = {
    header: {
        'Content-Type': 'text/html;charset=UTF-8',
    }
}

export const getLegalHtml = () => {
    return axios.get("/Legal/legal", legalConfig);
}