import React, { useState, useCallback, useEffect, forwardRef } from 'react';
import DatePicker from 'react-datepicker';

const DATE_FORMAT = 'MM/DD/YYYY';

const getDate = (date) => {
    return date ? new Date(date) : null;
}

export const DatePickerInput = ({ value, onChange, name}) => {
    const [date, setDate] = useState(value);
    const [invalidMessage, setInvalidMessage] = useState(null);

    let keysPressed = {};
    const onChangeCallback = useCallback((dt) => {
            setDate(dt);
            onChange(dt);
    }, [setDate, onChange]);

    useEffect(() => {
        if (!value) {
            setInvalidMessage('');
        }
    }, [value]);

    const onValidateCallback = useCallback((dt) => {
        var val = dt.toString();
        if (!val) {
            setInvalidMessage('');
            onChangeCallback(undefined);
        }
        else if (val && (!isNaN(val) || new Date(val).toString().indexOf('Invalid') > -1 || new Date() < new Date(val))) 
        {
            if (new Date() < new Date(val) && val.match(/^\d{2}([/])\d{2}\1\d{4}$/)) {
                onChangeCallback(new Date(val));
            }
            else {
                onChangeCallback(null);
            }
        }
        else {
            onChangeCallback(new Date(val));
        }

    }, [setDate, onChange]); 


    const onBlurCallback = useCallback((dt) => {
        setInvalidMessage('');
        var val = dt.currentTarget.value;
       
        if (!val) {
            setInvalidMessage('');
            onChangeCallback(undefined);
        }
        else if (val && (!isNaN(val) || new Date(val).toString().indexOf('Invalid') > -1 || new Date() < new Date(val))) {
            !isNaN(val) ? setInvalidMessage("Invalid date") :
                (new Date() < new Date(val) ? setInvalidMessage("Future date not allowed") : setInvalidMessage("Invalid date"));

            if (!val.match(/^\d{2}([/])\d{2}\1\d{4}$/)) {
                setInvalidMessage("Invalid date format")
            }

            if (val.indexOf('/') == -1) {
                setInvalidMessage("Invalid date")
            }

            if (new Date() < new Date(val) && val.match(/^\d{2}([/])\d{2}\1\d{4}$/)) {
                onChangeCallback(new Date(val));
            }
            else {
                onChangeCallback(val);
            }
        }
        else if (!val.match(/^\d{2}([/])\d{2}\1\d{4}$/)) {
            setInvalidMessage("Invalid date format");
            onChangeCallback(val);
        }
        else {
            onChangeCallback(new Date(val));
            }

    }, [setDate, onChange]);

    useEffect(() => {
        if (value === '') {
            setDate(null);
        }
    }, [value]);

    const onFocus = (e) => {
        if (e.target.placeholder.indexOf('Invalid') > -1 || e.target.placeholder.indexOf('Future') > -1){
            setDate(null);
        }
        setInvalidMessage(null);
    }

    const onKeyDown = (event) => {
        var regex_special_symbols = /[-!$%^&*()_+|~=`{}\[\]:\"';<>?,.@#]/;
        var charCode = (event.which) ? event.which : event.keyCode

        keysPressed[event.key] = true;
        if (keysPressed['Control'] && (event.keyCode == 17 || event.keyCode == 65 || event.keyCode == 67 || event.keyCode == 86 || event.keyCode == 88)) {

            return true;
        }
        else if ((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || event.keyCode == 32 || regex_special_symbols.test(event.key)) {
            event.preventDefault();
        }

        if (event.currentTarget.value) {
            if ((charCode >= 48 && charCode <= 57)) {
                onValidateCallback(event.currentTarget.value + event.key);
            }
            else {
                if (event.currentTarget.value.length != 1 && charCode != 8)
                    onValidateCallback(event.currentTarget.value);
            }
        }
    }

    return (
        <DatePicker
            className={invalidMessage ? 'form-control invalid-data' : 'form-control'}
            placeholderText={invalidMessage ? invalidMessage : DATE_FORMAT}
            name={name}
            selected={invalidMessage ? null : date}
            onChange={onChangeCallback}
            autoComplete='off'
            onBlur={onBlurCallback}
            onFocus={onFocus}
            maxDate={new Date()}
            onKeyDown={onKeyDown}
        />
    )

}