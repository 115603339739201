import React, { useEffect, useState, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import { useUser, useIsAuthenticated } from "../../store/user";
import { useToggle } from "../../services/hooks";
import { getLegalHtml } from "../../ajax/home";
import { useApp } from "../../store/app";

const copyRight = new Date().getFullYear() + " Epiq Systems All Rights Reserved";

const DefaultFooterText = ({ desc }) => {

    return (
        <div className="text-right">
            {desc} <br /> <a role="button" className="btn-link btn-link-email" href="mailto:customercare@aacer.com"> customercare@aacer.com</a>
        </div>
    )
}

const LegalContent = () => {

    const [html, setHtml] = useState({ __html: 'Loading...' });

    useEffect(() => {
        const exec = async () => {

            var h = await getLegalHtml();
            setHtml({ __html: h });
        }

        exec();
    }, [setHtml]);

    return <div dangerouslySetInnerHTML={html} />

}

const LegalModal = ({ show, onHide }) => {

    return (
        <Modal bsSize="large" show={show} onHide={onHide} aria-labelledby="contained-modal-title">
            <Modal.Header>
                <Modal.Title id="contained-modal-title" className="pull-left">Legal Information</Modal.Title>
                <button id="btnLegalCross" type="button" className="close" onClick={onHide}>
                    <span>&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <LegalContent />
            </Modal.Body>
            <Modal.Footer>
                <Button id="btnLegalClose" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

const EmployeeSSOButton = () => {

    const onClick = useCallback(() => {
        window.location.href = "/Account/SSOLogon";
    }, []);

    return <div>
        <button type="button" onClick={onClick} className="btn btn-link">Single Sign On</button>
    </div>
}

const Footer = () => {

    const { enableSSO } = useApp();
    const isAuthenticated = useIsAuthenticated();
    const { footerText } = useUser();
    let desc = "Contact Information";
    if (footerText) {
        var regex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i;
        var matches = footerText.match(regex);
        if (matches)
            desc = footerText.replace(matches[0], "");
    }

    const footer = <DefaultFooterText desc={desc} />;
    const [isOpen, toggle] = useToggle();

    return <>
        <div className="container-fluid">
            <hr />
            <div className="row" id="brandingFooter" style={{ paddingRight: 10 }}>
                <div className="col-md-6">
                    <div id="CopyRight">&#169; {copyRight}</div>
                    <div>
                        <Button
                            bsStyle="link"
                            onClick={toggle}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                            Legal Information
                         </Button>
                        <LegalModal show={isOpen} onHide={toggle} />
                    </div>
                    <div>
                        <a role="button" className="btn-link" href="http://www.epiqsystems.com/general.aspx?id=116" target="_blank">Privacy Policy</a>
                    </div>
                </div>
                <div className="col-md-6 text-right">
                    <div>{footer}</div>
                </div>
            </div>
            <br />
        </div>
    </>
}

export default Footer;