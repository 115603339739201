import { actionTypes } from "../actions/notification-queue-assignment-actions";

const updateObject = (oldObject, newValues) => {
	return Object.assign({}, oldObject, newValues);
}

export const reducer = (state = { selectedNotificationResults: [], currentIndex: 0, currentNotificationResult: undefined }, action) => {

	switch (action.type) {
		 case actionTypes.OPEN_QUEUE_ASSIGNMENT_MODAL: {
            return updateObject(state, {
                selectedNotificationResults: action.payload,
                currentIndex: 0,
                currentNotificationResult: action.payload[0]
            });
		 }
		case actionTypes.CLOSE_QUEUE_ASSIGNMENT_MODAL: {
            return updateObject(state, {
                selectedNotificationResults: [],
                currentIndex: 0,
                currentNotificationResult: undefined,
                currentAssignments: undefined });
		}
		case actionTypes.GET_QUEUE_ASSIGNMENTS_SUCCESS: {
			return updateObject(state, { currentQueueAssignments: action.payload });
		}
        case actionTypes.SAVE_QUEUE_ASSIGNMENTS_SUCCESS: {

            let newIndex = ++state.currentIndex;
            return updateObject(state, {
                currentIndex: newIndex,
                currentNotificationResult: state.selectedNotificationResults[newIndex],
                currentAssignments: undefined
            });
        }
        case actionTypes.UPDATE_QUEUE_ASSIGNMENTS: {
            let currentAssignments = updateObject(state.currentQueueAssignments, {assignedQueues: action.queues});
            return updateObject(state, { currentQueueAssignments: currentAssignments });
        }
		default:
			return state;
	}

}
