import { reducer as notificationQueueAssignmentReducer } from "./Notifications/reducers/notification-queue-assignment-reducers";
import { reducer as notificationFilterReducer } from "./Notifications/reducers/notification-filter-reducers";
import { reducer as fssReducer } from "./FullSocialSearch/reducers/get-fss-data-action-reducer";
import { getNotificationColumns } from "./Notifications/components/gridSettings/columns";
import { getFSSColumns } from "./FullSocialSearch/components/gridSettings/columns";

import {
    getGridPersitance,
    getGridReducers
} from "../shared/dataGrid";
import {
    searchFilterReducer
} from "../shared/searchFilter";
import {
    columnPickerReducer,
} from "../shared/columnPicker";



const pageState = window.__NOTIFICATION_PAGE_STATE__ ? JSON.parse(window.__NOTIFICATION_PAGE_STATE__) : {};
const userState = window.__USER_STATE__ ? JSON.parse(window.__USER_STATE__) : {}

export const ReducerFilters = {
    NOTIFICATION_FILTER_REDUCER_NAME: "notificationFilter",
    NOTIFICATION_GRID_REDUCER_NAME: "notificationGrid",
    NOTIFICATION_COLUMN_PICKER_REDUCER_NAME: "notificationColumnPicker",
    NOTIFICATION_QUEUE_ASSIGNMENT_REDUCER_NAME: "notificationQueueAssignmentReducer",

    FSS_GRID_REDUCER_NAME: "fssGrid",
    FSS_FILTER_REDUCER_NAME: "fssFilter"
}

let persitance = getGridPersitance(ReducerFilters.NOTIFICATION_GRID_REDUCER_NAME);
let gridSettings = persitance.getStorage();

let initialValues = {};
let initialValuesFSS = {};
let runSearchNow = false;

const getPaging = () => {
    var defaultPaging = {
        pageSize: 10,
        orderBy: "dateFiled",
        orderByDirection: "desc"
    };

    if (localStorage.getItem("notificationGridPaging") !== null) {
        var local = localStorage.getItem("notificationGridPaging");
        defaultPaging = local ? JSON.parse(local) : defaultPaging;
    }

    return {
        start: 0,
        pageIndex: 0,
        pageSize: defaultPaging["pageSize"],
        orderBy: defaultPaging["orderBy"],
        orderByDirection: defaultPaging["orderByDirection"]
    };
};



if (pageState.params && Object.keys(pageState.params).length > 0) {

    let params = pageState.params;
    Object.keys(pageState.params).forEach(x => {
        switch (x.toLowerCase()) {
            case "batchid":
                initialValues.notificationBatches = [Number(params.batchId)];
                return;
            case "statusoptionid":
                initialValues.notificationStatuses = [Number(params.statusOptionId)];
                return;
            case "queuenameid":
                initialValues.queues = [Number(params.queueNameId)];
                return;
            case "savedsearchesid":
                if (pageState['initialValues'] && pageState['initialValues']['definition'])
                    initialValues = JSON.parse(pageState.initialValues.definition);                
                return;
        }
    });
    runSearchNow = true;
}
else {
    initialValues.queues = [-3];
}




export const getInitialState = () => {
    let initialState = {};

    initialState[ReducerFilters.NOTIFICATION_FILTER_REDUCER_NAME] = {
        searchDefinitionTypeId: pageState.searchDefinitionTypeId,
        savedSearches: pageState.savedSearches,
        isCollapsed: pageState.isCollapsed,
        showSaveSearch: true,
        searchFilter: initialValues,
        initialFilter: initialValues,
        hitFields: [],
        statuses: pageState.notificationStatuses,
        queues: pageState.queues,
        chapters: pageState.chapters,
        ssns: pageState.ssns,
        score: pageState.score,
        courtStateList: pageState.courtStateList,
        fileSchema: pageState.fileSchema,
        operators: pageState.operators,
        batches: pageState.notificationBatches,
        scrubHitFields: pageState.scrubHitFields,
    }

    initialState[ReducerFilters.FSS_FILTER_REDUCER_NAME] = {
        isCollapsed: pageState.isCollapsed,
        showSaveSearch: false,
        searchFilter: initialValuesFSS,
        chapters: [
            { chapterId: 7, chapterDesc: "7" },
            { chapterId: 9, chapterDesc: "9" },
            { chapterId: 11, chapterDesc: "11" },
            { chapterId: 12, chapterDesc: "12" },
            { chapterId: 13, chapterDesc: "13" },
            { chapterId: 15, chapterDesc: "15" },
            { chapterId: 304, chapterDesc: "304" }
        ],
        ssns: pageState.ssns,
        courtStateList: pageState.courtStateList
    }

    let notificationDefaultColumns = gridSettings ? gridSettings.columns : getNotificationColumns(pageState.fileSchema, userState.useNotificationLock, userState.allowFullSocialSearch);
    initialState[ReducerFilters.NOTIFICATION_GRID_REDUCER_NAME] = {
        items: [],
        totalRecords: 0,
        paging: gridSettings ? gridSettings.paging : getPaging(),
        columns: notificationDefaultColumns,
        gridKey: "compositeKey",
    };

    initialState[ReducerFilters.NOTIFICATION_COLUMN_PICKER_REDUCER_NAME] = {
        isPickerCollapsed: true,
        columnPickerColumns: notificationDefaultColumns
    };

    initialState[ReducerFilters.NOTIFICATION_QUEUE_ASSIGNMENT_REDUCER_NAME] = {
        selectedNotificationResults: null
    };

    initialState[ReducerFilters.FSS_GRID_REDUCER_NAME] = {
        items: [],
        totalRecords: 0,
        columns: getFSSColumns()
    };

    return initialState;
}

export const getReducers = () => {
    return {
        notificationFilter: searchFilterReducer(ReducerFilters.NOTIFICATION_FILTER_REDUCER_NAME),
        notificationGrid: getGridReducers(ReducerFilters.NOTIFICATION_GRID_REDUCER_NAME),
        notificationColumnPicker: columnPickerReducer(ReducerFilters.NOTIFICATION_COLUMN_PICKER_REDUCER_NAME),
        notificationQueueAssignmentReducer: notificationQueueAssignmentReducer,
        notificationFilterReducer: notificationFilterReducer,
        fssGrid: fssReducer,// getGridReducers(ReducerFilters.FSS_GRID_REDUCER_NAME)
        fssFilter: searchFilterReducer(ReducerFilters.FSS_FILTER_REDUCER_NAME),
    };
}

export const watchStore = (store) => {
    persitance.setStorage(store);
}