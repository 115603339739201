import {  post } from '../../../../services/data-service';
import { actionTypes as searchFilterActions } from '../../../shared/searchFilter/search-filter-actions';


const getSearchResultsBySubscriberIdSuccess = (payload) => ({
  type: actionTypes.GET_FSS_SEARCH_RESULTS_SUCCESS,
  payload
});

export const toggleColumn = (payload) => ({
  type: actionTypes.TOGGLE_COLUMN,
  payload
});

export const actionTypes = {
  GET_FSS_SEARCH_RESULTS_SUCCESS: 'GET_FSS_SEARCH_RESULTS_SUCCESS',
  TOGGLE_COLUMN: 'TOGGLE_COLUMN'
};

export const searchFSS = (values, dispatch, props) => {
  let url = `/api/FullSocialSearch/GetSearchResults`;

  if (props.match.params && props.match.params.nrid) {
    url = url + `/${props.match.params.nrid}`;
  }

  if (values) {
    //dispatch({ type: searchFilterActions.SEARCH_FILTER_CLOSE, name: 'fssFilter' });
    dispatch({ type: searchFilterActions.UPDATE_FILTER_VALUES, name: 'fssFilter', payload: values });
  }

    return post(url, values).then((r) => {
        r.map(item => {
            item.bankruptcyChapter = parseInt(item.bankruptcyChapter)
        })
    dispatch(getSearchResultsBySubscriberIdSuccess(r));
  });
};
