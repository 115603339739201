import { actionTypes } from '../actions/get-fss-data-action';


const updateObject = (oldObject, newValues) => {
  return { ...oldObject, ...newValues };
};

export const reducer = (state = {}, action) => {

    switch (action.type) {
        case actionTypes.GET_FSS_SEARCH_RESULTS_SUCCESS:
            {
                return updateObject(state, { items: action.payload, totalRecords: action.payload.length });
            }
        case actionTypes.TOGGLE_COLUMN:
            {
                const newColumns = [];
                if (state.columns !== undefined) {
                    state.columns.forEach((col) => {
                        if (col.accessor === action.payload.accessor) {
                            col.show = action.payload.show;
                        }
                        newColumns.push(col);
                    });
                }
                return updateObject(state, { columns: newColumns });
            }
        default:
            return state;
    }
}
