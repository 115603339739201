import { get } from "../../../../services/data-service";

const getHitFieldsSuccess= (payload) => ({
    type: notificationFilterActionTypes.GET_HIT_FIELDS_SUCCESS,
    payload: payload
});

export const getHitFields = () => (dispatch, getState) => {
    get("/api/notification/getHitFields")
        .then((items) => {
            dispatch(getHitFieldsSuccess(items));
        });
};

export const notificationFilterActionTypes = {
    GET_HIT_FIELDS_SUCCESS: "GET_HIT_FIELDS_SUCCESS",
}