import React, { useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { useToggle } from "../../services/hooks";

const useGetBarDates = (items) => {


    const barDates = useMemo(() => {

        const barDates = [];
        for (var i = 0; items.barDateAlerts && i < items.barDateAlerts.length; i++) {
            let rowID = `barDateRow${i}`;
            barDates.push(<div key={rowID} id={rowID} style={{ verticalAlign: 'top', color: 'red' }}>{items.barDateAlerts[i].alertText}</div>)
        }

        return barDates;

    }, [items]);

    const notices = useMemo(() => {

        const notices = [];
        for (var i = 0; items.notices && i < items.notices.length; i++) {
            let rowID = `noticeRow${i}`;
            notices.push(<div key={rowID} id={rowID} style={{ verticalAlign: 'top', color: 'red' }}>{items.notices[i].alertText}</div>)
        }

        return notices;

    }, [items]);

    const alerts = useMemo(() => {

        const alerts = [];
        for (var i = 0; items.alertList && i < items.alertList.length; i++) {
            let rowID = `alertRow${i}`;
            let cell = [];

            cell.push(<td key={`cell${i}-postDate`} id={`cell${i}-postDate`}>{items.alertList[i].postDate}</td>)
            cell.push(<td key={`cell${i}-courtAbbr`} id={`cell${i}-courtAbbr`}>{items.alertList[i].courtAbbr}</td>)
            cell.push(<td key={`cell${i}-divName`} id={`cell${i}-divName`}>{items.alertList[i].divName}</td>)
            cell.push(<td key={`cell${i}-alertText`} id={`cell${i}-alertText`}>{items.alertList[i].alertText}</td>)

            alerts.push(<tr key={rowID} id={rowID}>{cell}</tr>)
        }

        return alerts;

    }, [items]);

    return { barDates, notices, alerts };
}



const Alerts =  ({ counts, items }) => {

    const [showModal, toggle] = useToggle();
    var { barDates, notices,  alerts} = useGetBarDates(items);


    return (
        <div className="pull-left">
            <Button id="alertButton" className="btn btn-link btn-alerts" onClick={toggle}>{counts}</Button>

            <Modal show={showModal} onHide={toggle} dialogClassName="modal-lg modal-content">
                <Modal.Header closeButton className="modal-header">
                    <Modal.Title>AACER Alerts</Modal.Title>
                </Modal.Header>
                <div className="modal-body">
                    {notices}
                    <Modal.Body>
                        <table id="alertsTable" className="table table-condensed table-striped">
                            <tr>
                                <th style={{ width: '20%' }}>Posted On (CT)</th>
                                <th style={{ width: '10%' }}>Court</th>
                                <th style={{ width: '10%' }}>Division</th>
                                <th style={{ width: '50%' }}>Notice</th>
                            </tr>
                            <tbody>
                                {alerts}
                            </tbody>
                        </table>
                    </Modal.Body>
                    {barDates}
                </div>
                <Modal.Footer className="modal-footer">
                    <Button onClick={toggle} id="btnAlertClose">Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default Alerts;