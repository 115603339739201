/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "./polyfills";
/** https://github.com/facebook/create-react-app/issues/9906 */
/** the jsx  runtime classic is becuase there is a bug in react-scripts 4.0.0 and 4.0.1** line after react-scripts-release */
import React, { Suspense, lazy } from 'react';

import './styles/main.css';
import 'react-block-ui/style.css';
import 'react-table/react-table.css';


import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import ReduxToastr from 'react-redux-toastr';
import { createBrowserHistory, createHashHistory } from 'history';

import { COMPONENTS } from './constants';
import Page from './components/layout/Page';
import LoadingSpinner from './components/shared/LoadingSpinner'; 
const HomePage = lazy(() => import('./components/home/HomePage'));
const ManagementPage = lazy(() => import('./components/admin/management/ManagementPage'));
const QueueSetupPage = lazy(() => import('./components/admin/queues/QueuesPage'));
const NotificationPage = lazy(() => import('./components/notification/notification-boot'));
const CashieringPage = lazy(() => import('./components/cashiering/cashiering-boot'));
const ReportsPage = lazy(() => import('./components/reports/ReportsPage'));
const LoginPage = lazy(() => import('./components/account/LoginPage'));

const root = document.getElementById('root');
const dataComponent = root.getAttribute('data-component');

// default to browser history
export const history = dataComponent === COMPONENTS.CashieringPage || dataComponent === COMPONENTS.NotificationPage || dataComponent === COMPONENTS.HomePage
	? createHashHistory() : createBrowserHistory();
const store = configureStore(history);

const App = ({ component: Component }) => {


	return <Provider store={store}>
		<Page>
			<Suspense fallback={<LoadingSpinner />}>
				<Component />
			</Suspense>
			<ReduxToastr
				timeOut={30000}
				removeOnHoverTimeOut={30000}
				newestOnTop={false}
				preventDuplicates
				position="top-right"
				transitionIn="fadeIn"
				transitionOut="fadeOut"
				progressBar
				getState={(state) => state.toastr}
			/>
		</Page>
	</Provider>;
};


const renderApp = (Component) => {

	return ReactDOM.render(<App component={Component} />, root);
}



if (dataComponent === COMPONENTS.HomePage)
	renderApp(HomePage);
if (dataComponent === COMPONENTS.ManagementPage)
	renderApp(ManagementPage);
if (dataComponent === COMPONENTS.NotificationPage)
	renderApp(NotificationPage);
if (dataComponent === COMPONENTS.CashieringPage)
	renderApp(CashieringPage);
if (dataComponent === COMPONENTS.ReportsPage)
	renderApp(ReportsPage);
if (dataComponent === COMPONENTS.QueueSetupPage)
	renderApp(QueueSetupPage);
if (dataComponent === COMPONENTS.LoginPage)
	renderApp(LoginPage);
