import { actionTypes } from '../actions/tvs-action-types';


const updateObject = (oldObject, newValues) => {
  return { ...oldObject, ...newValues };
};

export const reducer = (state = { items: [] }, action) => {
    switch (action.type) {
        case actionTypes.GET_TVS_SEARCH_RESULTS_SUCCESS: {
            return updateObject(state, {
                items: getLastViewedRow(action.payload.results, state.lastViewedRow),
                totalRecords: action.payload.totalResults,
                dateRetrieved: action.payload.dateRetrieved
            });
        }
        case actionTypes.CHANGE_PAGING_SIZE_TVS_GRID: {
            return updateObject(state, { pageSize: action.payload });
        }
        case actionTypes.CHANGE_SORT_TVS_GRID: {
            return updateObject(state, { sortBy: action.payload });
        }
        case actionTypes.TOGGLE_SELECT_ALL: {
            const updatedArray = state.items.map((e) => {
                e.selected = action.payload;
                return e;
            });
            return updateObject(state, {
                items: updatedArray,
                selectAllChecked: action.payload
            });
        }
        case actionTypes.TOGGLE_CHECK_BOX: {
            let isAllSelected = true;
            const updatedArray = state.items.map((e) => {
                if (e.voucherId.toString() === action.payload.id) {
                    e.selected = action.payload.selected;
                }
                if (!e.selected) {
                    isAllSelected = false;
                }
                return e;
            });
            return updateObject(state, {
                items: updatedArray,
                selectAllChecked: isAllSelected
            });
        }
        case actionTypes.TOGGLE_COLUMN_CHECKBOX: {
            const newColumns = state.columns.map((e) => {
                if (e.accessor === action.payload.accessor) {
                    e.show = action.payload.show;
                }
                return e;
            });
            const newResized = state.resized.filter((e) => e.id !== action.payload.accessor);
            return updateObject(state, { columns: newColumns, resized: newResized });
        }
        case actionTypes.UPDATE_STATUS_OPTION_FOR_VOUCHER_SUCCESS: {
            const updatedArray = state.items.map((e) => {
                if (action.payload.voucherId.includes(e.voucherId)) {
                    e.displayName = action.payload.displayName;
                    e.code = action.payload.code;
                }
                return e;
            });
            return updateObject(state, { items: updatedArray });
        }
        case actionTypes.RESET_PAGE: {
            return updateObject(state, {
                columns: action.payload.columns,
                sortBy: action.payload.sortBy,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                resized: []
            });
        }
        case actionTypes.CHANGE_PAGE: {
            return updateObject(state, { page: action.payload });
        }
        case actionTypes.TOGGLE_LOADING_INDICATOR_ON: {
            return updateObject(state, { loading: true });
        }
        case actionTypes.TOGGLE_LOADING_INDICATOR_OFF: {
            return updateObject(state, { loading: false });
        }
        case actionTypes.UPDATE_LAST_VIEWED: {
            return updateObject(state, { lastViewedRow: action.payload });
        }
        case actionTypes.RESIZE_COLUMN: {
            return updateObject(state, { resized: action.payload });
        }
        default:
            return state;
    }
}

const getLastViewedRow = (results, lastViewed) => {
    const updatedArray = results.map((e) => {
        e.lastViewed = (e.voucherId.toString() === lastViewed) ? 1 : 0;
        return e;
    });
    return updatedArray;
}
