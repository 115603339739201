import * as React from 'react';
import EmptyRowFormatter from '../../../shared/empty-row-formatter';

const TOOLTIP_MESSAGE = 'Data not available by the third party provider.';
const rowFormatter = (row) => {
  return (<EmptyRowFormatter rowValue={row.value} tooltipMessage={TOOLTIP_MESSAGE} />);
};

export const getTTHColumns = () => {
  const columns = [
    {
      Header: 'Date Received',
      accessor: 'checkDate',
      headerClassName: 'paymentDateHeader textalign-left',
      inColumnPicker: true,
      index: 2,
      show: true
    },
    {
      Header: 'Check Number',
      accessor: 'checkNumber',
        headerClassName: 'claimNumberHeader textalign-left',
      inColumnPicker: true,
      index: 3,
      show: true
    },
    {
      Cell: rowFormatter,
      Header: 'Name of Party',
      accessor: 'nameOfParty',
        headerClassName: 'partyDescriptionHeader textalign-left',
      inColumnPicker: true,
      index: 4,
      show: true
    },
    {
      Cell: rowFormatter,
      Header: 'Description',
      accessor: 'description',
        headerClassName: 'claimDescriptionHeader textalign-left',
      inColumnPicker: true,
      index: 5,
      show: true
    },
    {
        Header: 'Payment Amount',
      accessor: 'amount',
        headerClassName: 'paymentAmountHeader textalign-right',
      inColumnPicker: true,
      index: 6,
      show: true
    }
  ];
  return columns;
};
