import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';


const EmptyRowFormatter = ({ rowValue, tooltipMessage }) => {
    if (rowValue === null || rowValue === '') {
        const tooltip = (
            <Tooltip id='tooltipEmptyRow' className='in'>
                {tooltipMessage}
            </Tooltip>);
        return (
            <OverlayTrigger placement='top' overlay={tooltip} >
                <span>--</span>
            </OverlayTrigger>
        );
    } else {
        return (
            <span>{rowValue}</span>
        );
    }
}

export default EmptyRowFormatter;
