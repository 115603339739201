export const actionTypes = {
  CHANGE_PAGE: 'CASHIERING:TVS:CHANGE_PAGE',
  RESET_PAGE: 'CASHIERING:TVS:RESET_PAGE',
  GET_TVS_SEARCH_RESULTS_SUCCESS: 'CASHIERING:TVS:GET_TVS_SEARCH_RESULTS_SUCCESS',
  CHANGE_PAGING_SIZE_TVS_GRID: 'CASHIERING:TVS:CHANGE_PAGING_SIZE_TVS_GRID',
  CHANGE_SORT_TVS_GRID: 'CASHIERING:TVS:CHANGE_SORT_TVS_GRID',
  TOGGLE_CHECK_BOX: 'CASHIERING:TVS:TOGGLE_CHECK_BOX',
  TOGGLE_SELECT_ALL: 'CASHIERING:TVS:TOGGLE_SELECT_ALL',
  TOGGLE_COLUMN_CHECKBOX: 'CASHIERING:TVS:TOGGLE_COLUMN_CHECKBOX',
  UPDATE_STATUS_OPTION_FOR_VOUCHER_SUCCESS: 'CASHIERING:TVS:UPDATE_STATUS_OPTION_FOR_VOUCHER_SUCCESS',
  TOGGLE_LOADING_INDICATOR_ON: 'CASHIERING:TVS:TOGGLE_LOADING_INDICATOR_ON',
  TOGGLE_LOADING_INDICATOR_OFF: 'CASHIERING:TVS:TOGGLE_LOADING_INDICATOR_OFF',
  UPDATE_LAST_VIEWED: 'CASHIERING:TVS:UPDATE_LAST_VIEWED',
  RESIZE_COLUMN: 'CASHIERING:TVS:RESIZE_COLUMN'
};
