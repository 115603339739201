import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Field, FieldArray } from "redux-form";
import { SaveSearchButton } from "./save-search-form";

export class SearchFilter extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.props.onToggle();
    }

    render() {

        return (
            <div>
                <Row>
                    <Col id="filter-dev" xsOffset={10}>
                        <Button
                            className="pull-right"
                            bsSize="large"
                            bsStyle="link"
                            style={{ paddingRight: "10px" }}
                            onClick={this.toggle}
                            id="btnFilter"
                        >
                            <b
                                className={
                                    this.props.isCollapsed
                                        ? "fa fa-caret-down"
                                        : "fa fa-caret-up"
                                }
                            />
                            {" "}
                            Filter
                        </Button>
                    </Col>

                    {!this.props.isCollapsed && <SearchPanel {...this.props} />}

                </Row>

                <Row>
                    <Col xs={11}>
                        <strong>Filter Criteria:</strong>
                    </Col>
                    <Col xs={12}>
                        {this.props.submitFailed &&
                            <div
                                className="alert alert-danger"
                                data-bind="visible: showErrors() &amp;&amp; errorList().length > 0"
                            >
                                <strong>Error:</strong>
                                {" "}
                                Please correct your search filter to continue
                            </div>}
                        {!this.props.submitFailed &&
                            this.props.searchFilterDisplay}
                    </Col>
                </Row>

            </div>
        );
    }
}

class SearchPanel extends React.Component {
    constructor(props) {
        super(props);

        this.resetCommand = this.resetCommand.bind(this);
        this.searchCommand = this.searchCommand.bind(this);
        this.onSavedSearchChange = this.onSavedSearchChange.bind(this);
    }

    resetCommand() {
        this.props.onClear();
    }

    searchCommand() {
        this.props.onSearch();
    }

    onSavedSearchChange(e) {
        if (e.target.value) {
            this.props.onSelectSaveStatus(e.target.value);
        }
    }
    render() {
        const showErrors = (this.props.validationErrors !== undefined) && !isEmpty(this.props.validationErrors);
        return (
            <Row>
                <Col xs={12}>
                    <div className="search-criteria">
                        <Row>
                            <Col xs={12} className="form-inline">
                                {this.props.showSaveSearch &&
                                    <SaveSearchForm savedSearches={this.props.savedSearches} onChange={this.onSavedSearchChange} />}
                                {this.props.showSaveSearch &&
                                    <SearchButton
                                        {...this.props}
                                        searchCommand={this.searchCommand}
                                    />}
                                {this.props.showSaveSearch &&
                                    <ResetButton resetCommand={this.resetCommand} />}
                            </Col>
                        </Row>
                        <hr />
                        {
                            showErrors &&
                            <FormErrors {...this.props} />}
                        <Row>
                            <Col xs={12}>
                                <SearchPanelUL {...this.props} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xsOffset={9} xs={3}>
                                <SearchButton
                                    {...this.props}
                                    searchCommand={this.searchCommand}
                                />
                                <ResetButton resetCommand={this.resetCommand} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        );
    }
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

const SearchButton = props => {

    const disabled = (props.validationErrors !== undefined) && !isEmpty(props.validationErrors);
    return (
        <Button
            id={"btnSearch"}
            onClick={props.searchCommand}
            bsStyle="primary"
            style={{ marginLeft: "15px" }}
            disabled={props.isDisabled || disabled}
        >
            <b style={{ marginRight: "3px" }} className="fa fa-search" /> Search
        </Button>
    );
};

const ResetButton = props => {
    return (
        <button
            id={"btnClear"}
            className="btn"
            onClick={function (event) { props.resetCommand(); event.preventDefault() }}
            style={{ marginLeft: "5px" }}

        >
            {" "}Clear
        </button>
    );
};

const SaveSearchForm = props => {
    let { savedSearches, onChange } = props;
    return (
        <div style={{ display: "inline" }}>
            <span>Saved Filters:</span>
            <select id="ddlSavedFilters" className="form-control" style={{ minWidth: "200px" }} onChange={onChange} >
                <option />
                {savedSearches.map(s => (
                    <option key={s.searchDefinitionId} value={s.searchDefinitionId}>
                        {s.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

const SearchPanelUL = ({ children, showSaveSearch, fields, searchDefinitionTypeId, filter, getSavedSearches }) => {


    return (<ul className="list-unstyled list-inline filter-criteria">
        {fields.map((field, index) => <FilterField key={index}  {...field} />)}
        {children}
        {showSaveSearch && <li className="form-group" style={{ verticalAlign: "bottom" }}>
            <SaveSearchButton filter={filter} searchDefinitionTypeId={searchDefinitionTypeId} getSavedSearches={getSavedSearches} />
        </li>}
    </ul>)
}


const FilterField = ({ name, component, options, label, placeholder, isArray, ...extra }) => {

    if (isArray) {
        return (
            <FieldArray
                name={name}
                component={component}
                {...options}
                {...extra}
            />
        );
    }
    return (
        <Field
            name={name}
            label={label}
            component={component}
            placeholder={placeholder}
            options={options}
            {...extra}
        />
    );
};

const FormErrors = ({ validationErrors }) => {

    let errors = [];

    Object.keys(validationErrors).forEach(key => {
        errors.push(...validationErrors[key]);
    });

    return (
        <Row>
            <Col xs={12}>
                <div className="alert alert-danger">
                    <h5>
                        <b
                            style={{ marginRight: "10px" }}
                            className="fa fa-exclamation-triangle "
                        />
                        Please correct the following errors:
                    </h5>
                    <ul>
                        {errors.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            </Col>
        </Row>
    );
};
