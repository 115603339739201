import { useSelector } from 'react-redux';

const initialState = {};


export const reducer = (state = initialState, action) => {
    return state;
}


export const useApp = () => {
  const app = useSelector(state => state.app);
  return app;
}
