import { actionTypes } from '../actions/tvd-action-types';

const updateObject = (oldObject, newValues) => {
  return { ...oldObject, ...newValues };
};

export const reducer =
    (state = {}, action) => {
        switch (action.type) {
            case actionTypes.GET_TVD_SEARCH_RESULTS_SUCCESS:
                {
                    return updateObject(state,
                        {
                            dateRetrieved: action.payload.dateRetrieved,
                            items: action.payload.results,
                            totalRecords: action.payload.results.length,
                            totalNumberPayments: action.payload.totalCount
                        });
                }
            case actionTypes.CHANGE_PAGING_SIZE_TVD_GRID:
                {
                    return updateObject(state, { pageSize: action.payload });
                }
            case actionTypes.CHANGE_SORT_TVD_GRID:
                {
                    return updateObject(state, { sorted: action.payload });
                }
            case actionTypes.TOGGLE_COLUMN_CHECKBOX:
                {
                    const { columns } = state;
                    if (columns !== undefined) {
                        const newColumns = columns.map(
                            (element) => {
                                if (element.accessor === action.payload.accessor) {
                                    element.show = action.payload.show;
                                }
                                return element;
                            });
                        let newResized = [];
                        if (state.resized !== undefined) {
                            newResized = state.resized.filter((e) => e.id !== action.payload.accessor);
                        }
                        return updateObject(state, { columns: newColumns, resized: newResized });
                    }
                    return state;
                }
            case actionTypes.PAGE_RESET:
                {
                    return updateObject(state,
                        {
                            columns: action.payload.columns,
                            sorted: action.payload.sorted,
                            resized: []
                        });
                }
            case actionTypes.RESIZE_COLUMN:
                {
                    return updateObject(state, { resized: action.payload });
                }
            default:
                return state;
        }
    }
