import * as React from 'react';
import { FullSocialSearchPDFDownloadLink } from '../fss-pdf-download-link';
import { FullSocialSearchCaseRedirectLink } from '../fss-redirect-case-link';
import moment from 'moment';

export const getFSSColumns = () => {
    return (
        [
            {
                accessor: 'court',
                Header: 'Court',
                show: true,
                inColumnPicker: true,
                index: 0,
                width: 60
            },
            {  
                accessor: 'case',
                Header: 'Case',
                show: true,
                inColumnPicker: true,
                index: 1,
                Cell: (row) => {
                    const value = row.original.scn;
                    return (
                        <FullSocialSearchCaseRedirectLink caseNumber={value}
                            courtId={row.original.courtID}
                            id={row.original.caseID}/> 
                    );
                }
               
            },
            {
                accessor: 'bankruptcyChapter',
                Header: 'Chapter',
                show: true,
                inColumnPicker: true,
                index: 2,
                width: 60
            },
            {
                accessor: 'filedDateTime',
                Header: 'Date Filed',
                show: true,
                inColumnPicker: true,
                Cell: row => <div><span>{row.value != null ? moment(row.value).format('M/DD/YYYY') : ""}</span></div>,
                index: 3
            },
            {
                accessor: 'accountNumber',
                Header: 'Account Number',
                show: true,
                inColumnPicker: true,
                Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                index: 4
            },
            {
                accessor: 'partyName',
                Header: 'Debtor Name',
                show: true,
                inColumnPicker: true,
                Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                index: 5
            },
            {
                accessor: 'searchCompletedDateTime',
                Header: 'Search Completed',
                show: true,
                inColumnPicker: true,
                Cell: row => <div><span>{row.value != null ? moment(row.value).format('M/DD/YYYY') : ""}</span></div>,
                index: 6
            },
            {
                accessor: 'conUID',
                Header: 'ConUID',
                show: true,
                inColumnPicker: true,
                Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                index: 7
            },
            {
                accessor: 'searchRequestID',
                Header: 'Image',
                show: true,
                inColumnPicker: true,
                index: 8,
                Cell: (row) => {
                    const value: number = row.value;
                    return (
                        row.original.pdfPathExists ? < FullSocialSearchPDFDownloadLink id={value} /> : <p>PDF not available</p>
                    );
                }
            },
            {
                accessor: 'fullSSN',
                Header: 'Full SSN',
                show: true,
                inColumnPicker: true,
                index: 9,
                Cell: (row) => {
                    //return (redact(row.value));
                    return ('XXX-XX-' + row.value);
        }
     }
    ]
  );
};
