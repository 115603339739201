import React, { useCallback, useState, useRef, useEffect } from "react";
import $ from "jquery";
import 'bootstrap';
import 'bootstrap-select';

export const MultiSelect = ({ options, placeholder, input: { name, value, onChange },  ...props }) => {

    const [initialValues] = useState(value);
    const [initialized, setInitialized] = useState(false);

    const onSelected = useCallback((items) => {

        onChange(items);

    }, [onChange]);

    const ref = useRef(null)

    useEffect(() => {

        if (Array.isArray(value) && value.length === 0 && initialized && ref.current.selectedOptions.length > 0) {
            $(ref.current).selectpicker('deselectAll').selectpicker('refresh');
        }


    }, [value, ref, initialized])

    useEffect(() => {
        // using the same select that was used in the other pages because it seems to work better.
        // 

        const select = $(ref.current).selectpicker({ dropupAuto: false })
            .selectpicker('val', initialValues)
            .on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {

                onSelected(select.val().map(x => parseInt(x)));
            });

        setInitialized(true);

        return () => {
            $(ref.current).selectpicker('destroy');
        }

    }, [ref, onSelected, initialValues, setInitialized])


    return <div>
        <select name={name} ref={ref} multiple title={placeholder || "Any"}  >
                {options.map((o, index) =>
                    <option key={index} value={o.value}>{o.label}</option>
                )}
            </select>
        </div>
}

