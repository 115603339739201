import { actionTypes } from '../actions/trustee-transaction-history-action-types';

const updateObject = (oldObject, newValues) => {
  return { ...oldObject, ...newValues };
};

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.GET_TTH_SEARCH_RESULTS_SUCCESS:
            {
                return updateObject(state,
                    {
                        caseNumber: action.payload.caseInfo.shortCaseNumber,
                        loanNumber: action.payload.caseInfo.loanNumber,
                        trusteeName: action.payload.caseInfo.trusteeName,
                        lastUpdatedDate: action.payload.lastUpdatedDate,
                        items: action.payload.transactions,
                        totalRecords: action.payload.transactions.length
                    });
            }
        case actionTypes.CHANGE_PAGING_SIZE:
            {
                return updateObject(state, { pageSize: action.payload });
            }
        case actionTypes.CHANGE_SORT:
            {
                return updateObject(state, { sortBy: action.payload });
            }
        case actionTypes.PAGE_RESET:
            {
                return updateObject(state, { pageSize: action.payload.pageSize, sortBy: action.payload.sortBy });
            }
        default:
            return state;
    }
}
