import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';

export const NumberField = ({ setAsFormatted, value, onChange, numberFormat, placeholder, name, ...props }) => {


    const onFieldChange = useCallback((values) => {
        const setAsValue = setAsFormatted ? values.formattedValue : values.value;
        // this makes ie happy
        if (setAsValue !== value) {
            onChange(setAsValue);
        }
    }, [onChange, value]);

    return <>
        <NumberFormat
            name={name}
            {...props}
            onValueChange={onFieldChange}
            className="form-control"
            format={numberFormat}
            value={value}
            mask="_"
        />
    </>
}