import React from 'react';
import { ControlLabel, FormControl } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { DatePickerInput, MultiSelect } from '../form';
import { NumberField } from '../form/NumberField';


// assume all components are hooked to redux form

const getValidationState = (meta) => {
  return meta.touched && meta.error ? 'has-error' : '';
};

const SearchFilterListItem = (props) => {
  const { label, meta, children } = props;
  const className = 'form-group ' + getValidationState(meta);
  return (
    <li className={className}>
      <ControlLabel>{label}</ControlLabel>
      <div>
        {children}
      </div>
    </li>
  );
};

const AdvanceSearchFilterListItem = (props) => {
  const { label, meta, children } = props;
  const className = 'form-group ' + getValidationState(meta);


  return (
    <div className={className}>
      <ControlLabel>{label}</ControlLabel>
      <div>
        {children}
      </div>
    </div>
  );
};

export const MultiSelectFormControl = (props) => {
  const { input, inputProps, placeholder } = props;

  return (
    <SearchFilterListItem {...props}>
      <MultiSelect placeholder={placeholder} input={input} inputProps={inputProps} options={props.options} />
    </SearchFilterListItem>);
};

export const DatePickerFormControl = (props) => {
  const { onBlur, onChange, value, name, onDragStart, onDrop, onFocus } = props.input;
  return (
    <SearchFilterListItem {...props}>
      <DatePickerInput
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              name={name}
              onDragStart={onDragStart}
              onDrop={onDrop}
              onFocus={onFocus}
      />
    </SearchFilterListItem>
  );
};

export const InputFormControl = (props) => {
  const { input, inputProps, placeholder } = props;

  return (
    <SearchFilterListItem {...props}>
      <FormControl placeholder={placeholder} {...input} {...inputProps} />
    </SearchFilterListItem>
  );
};

export const InputFormMaskedControl = (props) => {
    //debugger;
    const { input, inputProps, placeholder, mask } = props;    
    return (
        <SearchFilterListItem {...props}>
            <InputMask mask={mask || '99-99999'} className="form-control" maskChar={null} placeholder={placeholder} {...input} {...inputProps} >
            </InputMask>
        </SearchFilterListItem>
    );
};

export const NumberFormControl = (props) => {
    const { input, inputProps, placeholder, numberFormat, setAsFormatted = false} = props;

    return (
        <SearchFilterListItem {...props}>
            <NumberField
                placeholder={placeholder}
                {...input}
                {...inputProps}
                numberFormat={numberFormat}
                setAsFormatted={setAsFormatted}
            />
        </SearchFilterListItem>
    );
};


export const AdvanceFilterItemFormControl = (props) => {
  const { input, inputProps, placeholder } = props;

  return (
    <AdvanceSearchFilterListItem {...props}>
      <FormControl placeholder={placeholder} {...input} {...inputProps} />
    </AdvanceSearchFilterListItem>
  );
};

export const AdvanceFilterItemSelectFormControl = (props) => {
  const { input, inputProps, placeholder, children } = props;

  return (
    <AdvanceSearchFilterListItem {...props}>
      <FormControl placeholder={placeholder} {...input} {...inputProps} componentClass='select'  >
        {children}
      </FormControl>
    </AdvanceSearchFilterListItem>
  );
};
