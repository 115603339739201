import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { API_URL } from '../constants';


const dataOnly = (response) => response.data;

export const createApiInstance = (baseURL) => {

    const instance = axios.create({
        baseURL
    });

    instance.interceptors.response.use(dataOnly, function (error) {
        if (error.response.status === 400) {

            // errors is an object, each key is an array of strings
            const errors = Object.keys(error.response.data.errors)
                .reduce((acc, key) => acc.concat(error.response.data.errors[key]), []);

            toastr.error(error.response.data.title, errors.join('\r\n'), { timeOut: 30000, removeOnHoverTimeOut: 30000 });
        } else if (error.response.status === 429 && error.response.config.url.includes('resetpassword')) {
            const msg = 'You have exceeded the maximum number of password reset attempts (3 attempts within 1 minute). For security reasons, you must wait 5 minutes before trying again.';
            toastr.error('Error', msg, { timeOut: 30000, removeOnHoverTimeOut: 30000 });
        } else {

            const msg = error.response.data && error.response.data.message;
            toastr.error('Error', msg || defaultMessage(error.response.status), { timeOut: 30000, removeOnHoverTimeOut: 30000 });
        }

        return Promise.reject(error);
    });

    return instance;
}


const defaultMessage = (status) => {
    switch (status) {
        case 401:
            return 'Not Authorized';
        case 403:
            return 'Insufficient permission';
        case 404:
            return 'Not Found';
        default:
            return 'Error while contacting the server';
    }
}

export default createApiInstance(API_URL);
