import * as React from "react";
import * as utils from "./utils";
import { Row, Col } from "react-bootstrap";
import * as Table from "reactabular-table";
import ReactPaginate from 'react-paginate';
import BlockUi from 'react-block-ui';
import * as sort from "sortabular";

import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend';
import * as dnd from 'reactabular-dnd';
import * as resolve from 'table-resolver';

const gridDefaults = {
    pageSizes: [10, 20, 25, 50, 100],
    totalRecords: 0
}

class InnerDataGrid extends React.Component {

    constructor(props) {
        super(props);
        let state = Object.assign({}, gridDefaults, {
            columns: this.getColumns(props.columns),
            sortingColumns: utils.getInitialSort(props.paging, 0)
        }, utils.getPagingSettingsFromProps(props.paging));

        this.state = state;

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.sort = this.sort.bind(this);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.paging.start !== this.state.start ||
            nextProps.paging.pageSize !== this.state.pageSize ||
            nextProps.totalRecords !== this.state.totalRecords) {

            let paging = utils.getPagingSettingsFromProps(Object.assign(nextProps.paging, { totalRecords: nextProps.totalRecords }));

            this.setState(paging);
        }

        if (this.compareColumnArrays(nextProps.columns, this.state.columns)) {
            let newColumns = this.getColumns(nextProps.columns);
            this.setState({ columns: newColumns });
        }
    }

    compareColumnArrays(columnsFromProps, columnsFromState) {
        var newProperties = columnsFromProps.map(function (x) { return x.property });
        var stateProperties = columnsFromState.map(function (x) { return x.property });
        
        return (newProperties.join(',') !== stateProperties.join(','));
    }

    handleChange(event) {

        let pageSize = parseInt(event.target.value);
        let totalRecords = this.state.totalRecords;
        this.setState({
            start: utils.getPageSizeOverflow(this.state.start, totalRecords, pageSize),
            pageSize: pageSize,
            pageCount: utils.getPageCount(totalRecords, pageSize),
            pageIndex: 0
        }, this.pageChanged);

    }

    handlePageClick(data) {
        let pageSize = this.state.pageSize;

        let start = Math.ceil(data.selected * pageSize);

        this.setState({
            start: start,
            pageIndex: utils.getPageIndex(start, pageSize)
        }, this.pageChanged);

    }

    pageChanged() {
        let { start, pageSize, pageIndex, columns, orderBy, orderByDirection } = this.state;
        this.props.onPagingInfoChanged({
            start: start,
            pageSize: pageSize,
            pageIndex: pageIndex,
            columns: columns,
            orderBy: orderBy,
            orderByDirection: orderByDirection
        });

    }
    
    render() {
        let { items, rowKey, onRow } = this.props;
        let { pageCount, pageSizes, pageIndex, pageSize, columns } = this.state;

        const components = {
            header: {
                cell: dnd.Header
            }
        };
        const resolvedColumns = resolve.columnChildren({ columns });

        return (
          
            <BlockUi
                blocking={this.props.isLoading}
                loader={<GridLoader />} >
                <Table.Provider
                    className="table table-condensed table-bordered table-striped table-hover"
                    components={components}
                    columns={resolvedColumns}>
                    <Table.Header
                        headerRows={resolve.headerRows({ columns })}

                    />
                    <Table.Body rows={items} rowKey={rowKey} onRow={onRow} />
                </Table.Provider>
                <Row>
                    <Col xs={10}>
                        {pageCount > 1 &&
                            <ReactPaginate previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={<a href="">...</a>}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                initialPage={pageIndex}
                                forcePage={pageIndex}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination margin-0"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />
                        }
                    </Col>
                    <Col xs={2}>

                        <div className="form-inline">
                            <div className="form-group pull-right">
                                <label>Page Size: </label>
                                <select onChange={this.handleChange} className="form-control" value={this.props.paging.pageSize}>
                                    {pageSizes.map(val => {
                                        
                                        return <option key={val} value={val}>{val}</option>;
                                    })};
                                    </select>

                            </div>
                        </div>
                    </Col>
                </Row>
            </BlockUi>

        )
    }

    sort() {
        let sortInfo = utils.getSortValues(this.state.sortingColumns)
        this.setState(sortInfo, this.pageChanged);

    }


    getColumns(columns) {

        const sortable = sort.sort({
            getSortingColumns: () => this.state.sortingColumns || [],
            strategy: sort.strategies.byProperty,
            onSort: selectedColumn => {
                this.setState({
                    sortingColumns: sort.byColumn({
                        sortingColumns: this.state.sortingColumns,
                        selectedColumn,
                        sortingOrder: {
                            FIRST: 'asc',
                            asc: 'desc',
                            desc: 'asc'
                        }
                    })
                }, this.sort);
            }
        });


        const sortableHeader = sortHeader(sortable, () => this.state.sortingColumns);
        //const filterableHeader = filterHeader(filterable);

        columns.forEach(element => {
            if (element.canSort) {
                element.header.formatters = element.header.formatters || [];
                element.header.formatters.push((name, extraParameters) => React.createElement("div", {}, sortableHeader(name, extraParameters)));
            }
        });
        return columns;
    }
}


const sortHeader = (sortable, getSortingColumns) => {
    return (value, { property }) => {
        const sortingColumns = getSortingColumns() || [];
        return (
            <div style={{ display: 'inline' }}>
                {React.createElement(
                    'span',
                    sortable(
                        value,
                        {
                            property
                        }
                    ), value
                )}
            </div>
        );
    };
}

const GridLoader = (props) => {
    return (<div className="alert alert-info" ><b className="fa fa-spinner fa-spin fa-2x" style={{ marginRight: "10px" }}></b> Loading</div>)
}


export const DataGrid = (props) => (
    <DndProvider backend={HTML5Backend}>
        <InnerDataGrid {...props} />
    </DndProvider>)