import React from 'react';
import Footer from './Footer';
import Header from './Header';
import Menu from './Menu';
import { useIsAuthenticated } from '../../store/user';

const Page = ({ children }) => {

    const isAuthenticated = useIsAuthenticated();
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.match(/trident/) || userAgent.match(/firefox/)) {

        return (
            <p className="no-browser-support">Effective June 15, 2022, the AACER2 site no longer supports the Internet Explorer or Mozilla Firefox browsers.<br />
                We recommend accessing AACER2 via <b>Chrome</b> or <b>Edge</b>.</p>
        );
    }
    else {
    return <>
        <div className="navbar-fixed-top zindexBanner">
            <div className="container-fluid" id="branding">
                <div className="row" id="logo">
                    <div className="col-xs-2">
                        <img src="/images/epiqAacerLogo.png" alt="Epiq Systems Logo" />
                    </div>
                    <div className="col-xs-10">
                        <div id="welcome">
                            <div id="headerApp">
                                <Header />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isAuthenticated && <div id="menuApp">
                <Menu />
            </div>}
        </div>
        <div style={{ minHeight: 600 }} className="page-body">
            {children}
        </div>
        <Footer />
       
    </>;
    }
}

export default Page;
