import axios from 'axios';
import { toastr } from 'react-redux-toastr';

export const get = (url) => {
    return handle(axios.get(url));
};

// tslint:disable-next-line:no-any
export const post = (url, payload)  =>  {
    return handle(axios.post(url, payload));
};

// tslint:disable-next-line:no-any
const handle = (promise) => promise.then((r) => {
    if (r.status >= 200 || r.status <= 299) {
        return r.data;
    } else {
        toastr.error('Error', 'Error while contacting the server', { timeOut: 30000, removeOnHoverTimeOut: 30000 });
        return Promise.reject(r);
    }
}).catch((r) => {
    if (r.response.status >= 500 || r.response.status < 400) {
        toastr.error('Error', 'Error while contacting the server', { timeOut: 30000, removeOnHoverTimeOut: 30000 });
    }
    if (r.response.status === 400) {
        toastr.error('Error', r.response.data, { timeOut: 30000, removeOnHoverTimeOut: 30000 });
    }
    if (r.response.status === 403) {
        toastr.error('Error', 'Insufficient permission', { timeOut: 30000, removeOnHoverTimeOut: 30000 });
    }
    return Promise.reject({ status: r.response.status, message: r.response.message || r.response.data.message || r.response.Message || r.response.data.Message });
});
