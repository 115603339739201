
export const getTVSColumns = () => {
  return ([
    {
      accessor: 'selected',
      show: true,
      inColumnPicker: false,
      sortable: false,
      maxWidth: 25,
      index: 0,
          headerClassName: 'selectedHeader textalign-left',
      resizable: false
    },
    {
      accessor: 'displayName',
      Header: 'Status',
      show: true,
      inColumnPicker: false,
      index: 1,
        headerClassName: 'statusHeader textalign-left'
    },
    {
      accessor: 'checkNumber',
      Header: 'Check #',
      show: true,
      inColumnPicker: true,
      index: 2,
        headerClassName: 'checkNumberHeader textalign-left'
    },
    {
      accessor: 'receivedDate',
      Header: 'Received Date',
      show: true,
      inColumnPicker: true,
      index: 3,
        headerClassName: 'receivedDateHeader textalign-left'
    },
    {
      accessor: 'checkDate',
      Header: 'Check Date',
      show: true,
      inColumnPicker: true,
      index: 4,
        headerClassName: 'checkDateHeader textalign-left'
    },
    {
      accessor: 'totalAmount',
      Header: 'Check Amount',
      show: true,
      inColumnPicker: true,
      index: 5,
        headerClassName: 'totalAmountHeader textalign-right'
    },
    {
      accessor: 'friendlyName',
      Header: 'Trustee Name',
      show: true,
      inColumnPicker: true,
      index: 6,
        headerClassName: 'friendlyNameHeader textalign-left'
    },
    {
      accessor: 'creditorName',
      Header: 'Payee Name',
      show: true,
      inColumnPicker: true,
      index: 7,
        headerClassName: 'creditorNameHeader textalign-left'
    },
    {
      accessor: 'totalItems',
      Header: 'No. of Payments',
      show: true,
      inColumnPicker: true,
      index: 8,
        headerClassName: 'totalItemsHeader textalign-right'
    },
    {
      accessor: 'voucherId',
      Header: '',
      show: false,
      inColumnPicker: false,
      index: 9
    },
    {
      accessor: 'lastViewed',
      Header: '',
      show: false,
      inColumnPicker: false,
      index: 10
    }
  ]);
};
