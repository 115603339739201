import { actionTypes } from "./data-grid-actions";
import { createNamedWrapperReducer } from "../../../services/redux-builder";

const updateObject = (oldObject, newValues) => {
    return Object.assign({}, oldObject, newValues);
}

const reducer = (state = { isLoading: false }, action) => {

    switch (action.type) {
        case actionTypes.DATA_GRID_UPDATE: {
            let paging = updateObject(state.paging, { start: action.start });
            return updateObject(state, { paging: paging });
        }
        case actionTypes.DATA_GRID_PAGING_INFO_CHANGE: {
            let paging = updateObject(state.paging, action.paging);
            return updateObject(state, { paging: paging });
        }
        case actionTypes.DATA_GRID_GET_ITEMS_SUCCESS: {
            return updateObject(state, { items: action.items, totalRecords: action.totalRecords, isLoading: false });
        }
        case actionTypes.DATA_GRID_GET_ITEMS: {
            return updateObject(state, { isLoading: true });
        }
        case actionTypes.DATA_GRID_GET_ERROR: {
            return updateObject(state, { isLoading: false });
        }
        case actionTypes.DATA_GRID_ITEM_UPDATE: {
            let items = state.items.concat([]);
            let index = items.findIndex(x => x[state.gridKey] == action.payload[state.gridKey]);

            if (index >= 0) {
                items[index] = action.payload;

                return updateObject(state, {
                    items: items,
                })
            } else {
                return state;
            }
        }
        case actionTypes.DATA_GRID_COLUMN_SHOWINDEX_UPDATE: {
            return updateObject(state, { columns: updateShowIndexForColumn(state.columns, action.paging) });
        }
        case actionTypes.DATA_GRID_MULTIPLE_ITEM_UPDATE: {
            let statusId = action.payload.statusId;
            let selectedItemIds = action.payload.selectedItemIds;
            let statusName = action.payload.statusName;

            let clonedItems = JSON.parse(JSON.stringify(state.items));

            for (var i = 0; i < clonedItems.length; i++) {
                if (selectedItemIds.indexOf(clonedItems[i].notificationResultID) >= 0) {
                    clonedItems[i].statusOptionId = statusId;
                    clonedItems[i].status = statusName;
                }
            }

            return updateObject(state, { items: clonedItems });
        }
        case actionTypes.DATA_GRID_COLUMN_ORDER_UPDATE: {
            let newColumns = [];

            newColumns = JSON.parse(JSON.stringify(action.paging));
            newColumns.sort(function (a, b) {
                return parseInt(a.showIndex) - parseInt(b.showIndex);
            });

            return updateObject(state, { columns: action.paging });
        }
        default:
            return state;

    }

}

const updateShowIndexForColumn = (stateColumns, changedColumns) => {
    let newColumns = [];
    let newOrderColumns = [];


    newColumns = changedColumns.filter(function (col) {
        return col.visible;
    });
    newOrderColumns = changedColumns.filter(function (col) {
        return !col.visible;
    });
  

    if (newColumns[newColumns.length - 1].property === "isSelected" ||
        newColumns[newColumns.length - 1].property === "frozenStatus" ||
        newColumns[newColumns.length - 1].property === "status") {

        if (newColumns[newColumns.length - 1].property === "isSelected") {
            newColumns.unshift(newColumns[newColumns.length - 1]);
        }
        else if (newColumns[newColumns.length - 1].property === "frozenStatus" &&
            newColumns[0].property === "isSelected") {
            newColumns.splice(1, 0, newColumns[newColumns.length - 1]);
        }
        else if (newColumns[newColumns.length - 1].property === "frozenStatus" &&
            newColumns[0].property !== "isSelected") {
            newColumns.unshift(newColumns[newColumns.length - 1]);
        }
        else if (newColumns[newColumns.length - 1].property === "status" &&
            newColumns[0].property !== "isSelected" &&
            newColumns[0].property !== "frozenStatus") {
            newColumns.unshift(newColumns[newColumns.length - 1]);
        }
        else if (newColumns[newColumns.length - 1].property === "status" &&
            newColumns[0].property === "isSelected" &&
            newColumns[1].property !== "frozenStatus") {
            newColumns.splice(1, 0, newColumns[newColumns.length - 1]);
        }
        else if (newColumns[newColumns.length - 1].property === "status" &&
            newColumns[0].property === "frozenStatus") {
            newColumns.splice(1, 0, newColumns[newColumns.length - 1]);
        }
        else if (newColumns[newColumns.length - 1].property === "status" &&
            newColumns[0].property === "isSelected" &&
            newColumns[1].property === "frozenStatus") {
            newColumns.splice(2, 0, newColumns[newColumns.length - 1]);
        }

        newColumns.pop();
    }

    newColumns.forEach((col, i) => {
        col.showIndex = i;
    })

    newColumns = newColumns.concat(newOrderColumns);

    return newColumns;
}

export const getGridReducers = (filterName) => {

    return createNamedWrapperReducer(reducer, filterName);
}