import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as formReducer } from "redux-form";
import thunk from 'redux-thunk';
import { reducer as toastrReducer } from 'react-redux-toastr'
import { connectRouter, routerMiddleware } from 'connected-react-router';
import * as UserStore from './user';
import * as AppStore from './app';
import * as HomeStore from './home';
import * as LegacyStore from './legacy';

const user = window.__USER_STATE__ ? JSON.parse(window.__USER_STATE__) : {};
const app = window.__APP_STATE__ ? JSON.parse(window.__APP_STATE__) : {};
const home = window.__HOME_PAGE_STATE__ ? JSON.parse(window.__HOME_PAGE_STATE__) : {};



const initialState = {
    user,
    app,
    home
}


export default function configureStore(history) {
    const reducers = {
        toastr: toastrReducer,
        user: UserStore.reducer,
        app: AppStore.reducer,
        router: connectRouter(history),
        home: HomeStore.reducer,
        form: formReducer
    };

    const legacyReducers = LegacyStore.getReducers();
    const legacyState = LegacyStore.getInitialState();

    const allReducers = { ...reducers, ...legacyReducers };
    const combinedState = { ...initialState, ...legacyState };
    

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const rootReducer = combineReducers(allReducers);

    const store = createStore(
        rootReducer,
        combinedState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    LegacyStore.persist(store);
    return store;
}
