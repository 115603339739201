import * as R from "redux";


const toggleColumnPicker = (name) => ({
    type: columnActionTypes.COLUMN_PICKER_TOGGLE,
    name: name
});

const closeColumnPicker = (name) => ({
    type: columnActionTypes.COLUMN_PICKER_CLOSE,
    name: name
});

const updateColumnVisible = (name, column) => ({
    type: columnActionTypes.COLUMN_PICKER_COLUMN_VISIBLE_UPDATE,
    name: name,
    column: column
});

const changeColumnOrder = (name, source, target, propColumns, stateColumns) => ({
    type: columnActionTypes.COLUMN_PICKER_COLUMN_ORDER_UPDATE,
    name: name,
    source: source,
    target: target,
    propColumns: propColumns,
    stateColumns: stateColumns
});

const refreshColumnPicker = (name, payload) => ({
    type: columnActionTypes.COLUMN_PICKER_REFRESH,
    name: name,
    columns: payload
});

export const columnActionTypes = {
    COLUMN_PICKER_TOGGLE: "COLUMN:PICKER:TOGGLE",
    COLUMN_PICKER_CLOSE: "COLUMN:PICKER:CLOSE",
    COLUMN_PICKER_COLUMN_VISIBLE_UPDATE: "COLUMN:PICKER:COLUMN:VISIBLE:UPDATE",
    COLUMN_PICKER_COLUMN_ORDER_UPDATE: "COLUMN:PICKER:COLUMN:ORDER:UPDATE",
    COLUMN_PICKER_REFRESH: "COLUMN:PICKER:REFRESH"
}

export const columnPickerActions = (name) => {

    return {
        toggleColumnPicker: () => toggleColumnPicker(name),
        closeColumnPicker: () => closeColumnPicker(name),
        updateColumnVisible: (column) => updateColumnVisible(name, column),
        changeColumnOrder: (source, target, propColumns, stateColumns) => changeColumnOrder(name, source, target, propColumns, stateColumns),
        refreshColumnPicker: (payload) => refreshColumnPicker(name, payload)
    }
}