import {
  getGridPersitance
} from '../shared/genericDataGrid';
import { reducer as voucherReducer } from './TrusteeVoucherSummary/reducers/tvs-reducer';
import { getTVSColumns } from './TrusteeVoucherSummary/components/gridSettings/tvs-columns';
import { reducer as detailReducer } from './TrusteeVoucherDetails/reducers/tvd-reducer';
import { getTVDColumns } from './TrusteeVoucherDetails/components/gridSettings/tvd-columns';
import { reducer as historyReducer } from './TrusteeTransactionHistory/reducers/trustee-transaction-history-reducer';
import { getTTHColumns } from './TrusteeTransactionHistory/components/gridSettings/trustee-transaction-history-columns';

import { searchFilterReducer } from '../shared/searchFilter';

const pageState = window.__CASHIERING_PAGE_STATE__ ? JSON.parse(window.__CASHIERING_PAGE_STATE__) : {};
const initialState = {};

export const ReducerFilters = {
  VOUCHER_SUMMARY_REDUCER_NAME: 'voucherSummaryReducer',
  VOUCHER_SUMMARY_FILTER_NAME: 'voucherSummaryFilterReducer',
  VOUCHER_DETAILS_REDUCER_NAME: 'voucherDetailsReducer',
  TRANSACTION_HISTORY_REDUCER_NAME: 'transactionHistoryReducer',
  TRANSACTION_HISTORY_FILTER_NAME: 'transactionHistoryFilterReducer'
};

const voucherSummaryPersitance = getGridPersitance(ReducerFilters.VOUCHER_SUMMARY_REDUCER_NAME);
const voucherSummaryGridSettings = voucherSummaryPersitance.getStorage();
initialState[ReducerFilters.VOUCHER_SUMMARY_REDUCER_NAME] = {
  items: [],
  totalRecords: 0,
  page: 0,
  pageSize: voucherSummaryGridSettings ? voucherSummaryGridSettings.pageSize : 10,
  sortBy: voucherSummaryGridSettings ? voucherSummaryGridSettings.sortBy :
    [{
      id: '',
      desc: false
    }],
  dateRetrieved: 'No data retrieved',
  selectAllChecked: false,
  columns: voucherSummaryGridSettings ? voucherSummaryGridSettings.columns : getTVSColumns(),
  voucherStatusOptions: pageState.statusOptions,
  loading: true,
  resized: [],
  lastViewedRow: voucherSummaryGridSettings ? voucherSummaryGridSettings.lastViewedRow : ''
};

initialState[ReducerFilters.VOUCHER_SUMMARY_FILTER_NAME] = {
  showSaveSearch: false,
  searchFilter: {},
  isCollapsed: true
};

initialState[ReducerFilters.TRANSACTION_HISTORY_FILTER_NAME] = {
  showSaveSearch: false,
  searchFilter: {},
  isCollapsed: true
};

const voucherDetailsPersitance = getGridPersitance(ReducerFilters.VOUCHER_DETAILS_REDUCER_NAME);
const voucherDetailsSettings =  voucherDetailsPersitance.getStorage();
initialState[ReducerFilters.VOUCHER_DETAILS_REDUCER_NAME] = {
  items: [],
  totalRecords: 0,
  pageSize: voucherDetailsSettings ? voucherDetailsSettings.pageSize : 10,
    sorted: voucherDetailsSettings ? voucherDetailsSettings.sortBy :
            [{
                id: '',
                desc: false
            }],
    dateRetrieved: 'No data retrieved',
    columns: voucherDetailsSettings ? voucherDetailsSettings.columns : getTVDColumns(),
  totalNumberPayments: 0,
  resized: []
};

const transactionHistoryPersistance = getGridPersitance(ReducerFilters.TRANSACTION_HISTORY_REDUCER_NAME);
const transactionHistoryGridSettings = transactionHistoryPersistance.getStorage();
initialState[ReducerFilters.TRANSACTION_HISTORY_REDUCER_NAME] = {
  items: [],
  totalRecords: 0,
  columns: getTTHColumns(),
  defaultColumns: getTTHColumns(),
  pageSize: transactionHistoryGridSettings ? transactionHistoryGridSettings.pageSize : 10,
  sortBy: transactionHistoryGridSettings ? transactionHistoryGridSettings.sortBy :
    [{
      id: '',
      desc: false
    }],
  lastUpdatedDate: undefined,
  totalNumberPayments: 0
};


export const getInitialState = () => {
    return initialState;
}

export const getReducers = () => {
    return {
        voucherSummaryReducer: voucherReducer,
        voucherDetailsReducer: detailReducer,
        transactionHistoryReducer: historyReducer,
        voucherSummaryFilterReducer: searchFilterReducer(ReducerFilters.VOUCHER_SUMMARY_FILTER_NAME),
        transactionHistoryFilterReducer: searchFilterReducer(ReducerFilters.TRANSACTION_HISTORY_FILTER_NAME)
    }
}

export const watchStore = (store) => {
    voucherSummaryPersitance.setStorage(store);
    transactionHistoryPersistance.setStorage(store);
    voucherDetailsPersitance.setStorage(store);
}