import { useState, useCallback, useEffect } from 'react';
import useLatest from 'use-latest';

export const useToggle = (initial = false) => {

    const [value, setValue] = useState(initial);

    const toggleValue = useCallback((v) => {

        if (v === true) setValue(true);
        else if (v === false) setValue(false);
        else setValue(c => !c)
    }, []);

    return [value, toggleValue];
}

const savedChangesMessage = "You have unsaved changes on this page.  Are you sure you want to leave?";

export const useBeforeunload = (handler) => {

    const handlerRef = useLatest(handler);

    useEffect(() => {
        const handleBeforeunload = (event) => {
            let returnValue;
            if (handlerRef.current != null && handlerRef.current()) {
                event.preventDefault();
                returnValue = savedChangesMessage
            }

            // Chrome requires `returnValue` to be set.
            if (event.defaultPrevented) {
                event.returnValue = '';
            }

            if (typeof returnValue === 'string') {
                event.returnValue = returnValue;
                return returnValue;
            }
        };

        window.addEventListener('beforeunload', handleBeforeunload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeunload);
        };
    }, []);
}