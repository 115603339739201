import { useState } from 'react';
import { useSelector } from 'react-redux';

const initialState = {};

export const reducer = (state = initialState, action) => {
    return state;
}


export const useUser = () => {
  const user = useSelector(state => state.user);
  return user;
}

export const useIsAuthenticated = () => {
    const user = useUser();
    const [isAuthenticated] = useState('email' in user);

    return isAuthenticated;
}