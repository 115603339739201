import React from 'react';
import EmptyRowFormatter from '../../../shared/empty-row-formatter';

const TOOLTIP_MESSAGE = 'Data not available by the third party provider.';
const rowFormatter = (row) => {
  return (<EmptyRowFormatter rowValue={row.value} tooltipMessage={TOOLTIP_MESSAGE} />);
};

export const getTVDColumns = () => {

    const columns = [
        {
            Header: 'Loan Number',
            accessor: 'loanNumber',
            headerClassName: 'loanNumberHeader textalign-left',
            inColumnPicker: true,
            index: 0,
            show: true
        },
        {
            Header: 'Debtor Name',
            accessor: 'debtorName',
            headerClassName: 'debtorNameHeader textalign-left',
            inColumnPicker: true,
            index: 1,
            show: true
        },
        {
            Cell: (row) => {
                const url = `/Case/Detail/${row.original.caseId}`
                    + `?courtId=${row.original.courtId}`
                    + `&acbcn=true#Claims`;
                const id = `case-link-${row.original.caseId}`;
                return (
                    <a
                        href={url}
                        id={id}
                    >
                        {row.value}
                    </a>
                );
            },
            Header: 'Case Number',
            accessor: 'caseNumber',
            headerClassName: 'caseNumberHeader textalign-left',
            inColumnPicker: true,
            index: 2,
            show: true
        },
        {
            Header: 'Claim Number',
            accessor: 'claimNumber',
            headerClassName: 'claimNumberHeader textalign-left',
            inColumnPicker: true,
            index: 3,
            show: true
        },
        {
            Cell: rowFormatter,
            Header: 'Claim Description',
            accessor: 'claimDescription',
            headerClassName: 'claimDescriptionHeader textalign-left',
            inColumnPicker: true,
            index: 4,
            show: true
        },
        {
            Header: 'Payment Date',
            accessor: 'paymentDate',
            headerClassName: 'paymentDateHeader textalign-left',
            inColumnPicker: true,
            index: 5,
            show: true
        },
        {
            Header: 'Payment Amount',
            accessor: 'paymentAmount',
            headerClassName: 'paymentAmountHeader textalign-right',
            inColumnPicker: true,
            index: 6,
            show: true
        },
        {
            Header: 'Type',
            accessor: 'voucherDetailsType',
            headerClassName: 'voucherDetailsTypeHeader textalign-left',
            inColumnPicker: true,
            index: 7,
            show: true
        },
        {
            Cell: rowFormatter,
            Header: 'Payment Description',
            accessor: 'paymentDescription',
            headerClassName: 'paymentDescriptionHeader textalign-left',
            inColumnPicker: true,
            index: 8,
            show: true
        },
        {
            Header: 'Transaction History',
            accessor: 'requestedMatchedClaimId',
            headerClassName: 'tranHistoryHeader textalign-left',
            inColumnPicker: true,
            index: 9,
            show: true
        },
        {
            Header: 'Matched Claim Id',
            accessor: 'matchedClaimId',
            headerClassName: 'matchedClaimIdHeader textalign-left',
            inColumnPicker: false,
            index: 10,
            show: false
        },
        {
            Header: 'Claim Type',
            accessor: 'claimType',
            headerClassName: 'claimTypeHeader textalign-left',
            inColumnPicker: true,
            index: 11,
            show: false
        },
        {
            Header: 'Final Report Category',
            accessor: 'finalReportCategory',
            headerClassName: 'finalReportCategoryHeader textalign-left',
            inColumnPicker: true,
            index: 12,
            show: false
        }
    ];
    return columns;
}
