import { notificationFilterActionTypes as actionTypes } from "../actions/notification-filter-actions";

const updateObject = (oldObject, newValues) => {
    return Object.assign({}, oldObject, newValues);
}

export const reducer = (state = { hitFields: [] }, action) => {
    switch (action.type) {
        case actionTypes.GET_HIT_FIELDS_SUCCESS:
        {
            return updateObject(state,
                {
                    hitFields: action.payload,
                });
        }
        default:
            return state;
    }
}