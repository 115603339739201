import { get, post } from "../../../../services/data-service";
import { toastr } from 'react-redux-toastr';

const getQueueAssignmentsSuccess= (payload) => ({
	type: actionTypes.GET_QUEUE_ASSIGNMENTS_SUCCESS,
	payload: payload
});


const saveQueueAssignmentsSuccess= () => ({
	type: actionTypes.SAVE_QUEUE_ASSIGNMENTS_SUCCESS
});

export const openQueueAssignmentModal= (payload) => ({
 	type: actionTypes.OPEN_QUEUE_ASSIGNMENT_MODAL,
 	payload: payload
 });

export const closeQueueAssignmentModal= () => ({
	type: actionTypes.CLOSE_QUEUE_ASSIGNMENT_MODAL
});

export const updateQueueAssignments= (queues) => ({
    type: actionTypes.UPDATE_QUEUE_ASSIGNMENTS,
    queues: queues
});



export const actionTypes = {
	SAVE_QUEUE_ASSIGNMENTS_SUCCESS: "SAVE_QUEUE_ASSIGNMENTS_SUCCESS",
	GET_QUEUE_ASSIGNMENTS_SUCCESS: "GET_QUEUE_ASSIGNMENTS_SUCCESS",
	OPEN_QUEUE_ASSIGNMENT_MODAL: "OPEN_QUEUE_ASSIGNMENT_MODAL",
    CLOSE_QUEUE_ASSIGNMENT_MODAL: "CLOSE_QUEUE_ASSIGNMENT_MODAL",
    UPDATE_QUEUE_ASSIGNMENTS: "UPDATE_QUEUE_ASSIGNMENT"
}

export const getQueueAssignments = (notificationResultId) => (dispatch, getState) => {
	get(`/api/notification/getNotificationQueues?notificationResultId=${notificationResultId}`).then((r) => {
		dispatch(getQueueAssignmentsSuccess(r));
	});
};

export const saveQueueAssignments = (payload, callBack) => (dispatch, getState) => {

    post("/api/notification/updateQueues", payload).then((r) => {
        toastr.success("Success!", "Queues Successfully Updated", { timeOut: 30000, removeOnHoverTimeOut: 30000 })
        let state = getState();
        let reducer = state.notificationQueueAssignmentReducer;
        if (reducer.currentIndex + 1 === reducer.selectedNotificationResults.length) {
            callBack()
            dispatch(closeQueueAssignmentModal());
        } else {
            dispatch(saveQueueAssignmentsSuccess());
        }
	});
}
