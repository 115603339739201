import * as React from "react";
import InputMask from 'react-input-mask';
import { FormControl, Col, Checkbox, FormGroup, ControlLabel } from "react-bootstrap";


const getValidationState = (meta) => {
    return meta.touched && meta.error ? 'error' : undefined;
}

const BootstrapMarkup = ({ meta, input, label, children, labelProps, inputColProps } ) => {


    return (
        <FormGroup controlId={input.name} validationState={getValidationState(meta)}>
            <Col componentClass={ControlLabel} {...(labelProps || { xs: 2 }) } >
                {label}
            </Col>
            <Col {...(inputColProps || { xs: 10 }) }>
                {children}
                {
                    (meta.submitFailed ||meta.touched) && meta.error &&
                    <span className="help-block">{meta.error}</span>
                }
            </Col>
        </FormGroup>
    );

}

export const InputFormMaskedControl = ({ input, inputProps, placeholder, mask }) => {

    return (
        <InputMask mask={mask || '99-99999'} className="form-control" maskChar={null} placeholder={placeholder} {...input} {...inputProps} >
        </InputMask>);
}

export const InputFormControl = ({ input, inputProps, type, placeholder }) => {

    return (
        <FormControl placeholder={placeholder} {...input} type={type} {...inputProps} >
        </FormControl>);
}

export const InputFormGroup = (props) => {

    return (<BootstrapMarkup {...props}>
        <InputFormControl {...props} />
    </BootstrapMarkup>);
}



export const CheckboxFormGroup = ({ input, label }) => {
    return (
        <FormGroup>
            <Col xsOffset={2} xs={10} >
                <Checkbox {...input} >{label}</Checkbox>
            </Col>
        </FormGroup>
    );
}