import React, { useEffect, useState, useCallback } from 'react';
import { ButtonGroup, MenuItem, Dropdown } from 'react-bootstrap';
import { OktaAuth } from '@okta/okta-auth-js'
import AACERHeaderAlerts from './Alerts';
import { useUser, useIsAuthenticated } from '../../store/user';
import { useApp } from '../../store/app';
import { getAlertCounts, getAlerts } from '../../ajax/alerts';



const DebugMenuItem = () => {
    const {
        allowMonitoring, allowNotification, isAdmin, userName, login, userId, userGroupId,
        asapSubsriberId, subStatusId, mngAuthId, subName, subId
    } = useUser();

    const { version } = useApp();

    const caseMonitorRole = allowMonitoring ? `fa fa-check-square-o` : `fa fa-square-o`;
    const notificationRole = allowNotification ? `fa fa-check-square-o` : `fa fa-square-o`;
    const adminClass = isAdmin ? `fa fa-check-square-o` : `fa fa-square-o`;
    return (
        <li className='dropdown-submenu pull-left' id='header-debug-dropdown'>
            <a href='#'>Debug Info</a>
            <ul className='dropdown-menu'>
                <li><span>Name: {userName}</span></li>
                <li><span>Login: {login}</span></li>
                <li><span>UserId: {userId}</span></li>
                <li><span>Sub Name: {subName}</span></li>
                <li><span>Sub Id: {subId}</span></li>
                <li><span>User Group: {userGroupId}</span></li>
                <li><span>ASAP Sub Id:{asapSubsriberId}</span></li>
                <li><span>Sub Status: {subStatusId}</span></li>
                <li><span>Mng Auth Id: {mngAuthId}</span></li>
                <li><span>Case Monitor role: <b className={caseMonitorRole} /></span></li>
                <li><span>Notifications role: <b className={notificationRole} /></span></li>
                <li><span>Admin role:  <b className={adminClass} /></span></li>
                <li><span>Version: {version}</span></li>
            </ul>
        </li>
    );
};




const AACERHeader = () => {


    const [counts, setCounts] = useState(0);
    const [alerts, setAlerts] = useState([]);
    const isAuthenticated = useIsAuthenticated();

    const { hideUserGroupName, userName, subName, userGroupName } = useUser();
    const { isDebugMode, oktaIssuer, enableSSO } = useApp();

    var config = {
        issuer: oktaIssuer,
    };

    var authClient = new OktaAuth(config);

    useEffect(() => {
        if (isAuthenticated) {
            Promise.all([getAlertCounts(), getAlerts()]).then((response) => {
                setCounts(response[0]);
                setAlerts(response[1]);
            });
        }

    }, [setCounts, setAlerts, isAuthenticated])

    const signOut = useCallback(() => {
        window.sessionStorage.clear();
        window.localStorage.clear();
        authClient.session.exists()
            .then(function (exists) {
                if (exists) {
                    //ToDo : will remove 
                    console.log('okta session exists : ', exists);
                    // logged in
                    authClient.signOut().then(function () {
                        authClient.session.exists().then(function (exists) {
                            //ToDo : will remove 
                            console.log('okta session cleared :', exists);
                            if (!exists) {
                                window.location.href = '/Account/LogOff';
                            }
                        });
                    });
                } else {
                    // not logged in
                    window.location.href = '/Account/LogOff';
                }
            });

    }, []);

    if (!isAuthenticated) {
        return null;
    }

    return (
        <ButtonGroup className='header-btn-group pull-right'>
            <AACERHeaderAlerts items={alerts} counts={counts} />
            <Dropdown id='header-user-dropdown'>
                <Dropdown.Toggle bsStyle='link' style={{ color: '#ffffff', fontSize: '16pt' }}>
                    <i className='fa fa-user' />
                </Dropdown.Toggle>
                <Dropdown.Menu className='dropdown-menu-right'>
                    <li className='dropdown-info'>
                        <div>
                            <div>User: {userName} </div>
                            <div>Subscriber: {subName}</div>
                            {!hideUserGroupName && <div>User Group: {userGroupName} </div>}
                        </div>
                    </li>
                    <MenuItem divider={true} />
                    <MenuItem eventKey='1' onClick={signOut} >Sign Out</MenuItem>
                    {isDebugMode && <>
                        <MenuItem divider={true} />
                        <DebugMenuItem />
                    </>}
                </Dropdown.Menu>
            </Dropdown>
        </ButtonGroup>
    );
}

export default AACERHeader;