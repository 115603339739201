import { actionTypes } from "./search-filter-actions";
import { createNamedWrapperReducer } from "../../../services/redux-builder";

const updateObject = (oldObject, newValues) => {
    return Object.assign({}, oldObject, newValues);
}

const reducer = (state = {}, action) => {

    switch (action.type)
    {
        case actionTypes.SEARCH_FILTER_TOGGLE:{
            return updateObject(state, {isCollapsed: !state.isCollapsed});
        }
        case actionTypes.SEARCH_FILTER_CLOSE: {
            return updateObject(state, { isCollapsed: true });
        }
        case actionTypes.GET_SAVED_SEARCHES_SUCCESS: {
            return updateObject(state, { savedSearches: action.payload });
        }
        case actionTypes.UPDATE_FILTER_VALUES: {
            return updateObject(state,  { searchFilter: action.payload });
        }
       
        default:
            return state;
    }

}

export const searchFilterReducer = (filterName) => {

    return createNamedWrapperReducer(reducer, filterName); 
}