import * as React from "react";
import { Button } from 'react-bootstrap';
import moment from 'moment'
import numeral from "numeral";
import { Popover, OverlayTrigger } from "react-bootstrap";


export const checkboxColumn = (label, property, onCheck, selectAllButton, visible, shouldShowThisCheckboxFunction, metaDataFunction) => {
	return {
		header: {
            label: label,
            formatters: [
                () => selectAllButton
            ]
		},
		property: property,
		props: {
			className: "text-center"
		},
		cell: {
			formatters: [
				(value, { rowData }) => {
					let showThisCheckbox = true;

					if (shouldShowThisCheckboxFunction)
					{
						showThisCheckbox = shouldShowThisCheckboxFunction(rowData);
					}
										
					let checkboxCssClass = (showThisCheckbox) ? "show" : "hidden";

					let metaData = (metaDataFunction) ? metaDataFunction(rowData) : "";

					return (
						<input type="checkbox" className={checkboxCssClass} checked={rowData[property]} onClick={() => onCheck(rowData)} data-metadata={ metaData }></input>
					)
				}
			]
        },
        visible: visible ? true : visible,
        canRearrange: false
	}
}

export const editButtonColumn = (onEdit) => {
	return {
		props: {
			style: {
				width: 50
			}
		},
		cell: {
			formatters: [
				(value, { rowData }) => (
					<Button bsSize="xsmall" className="btn-pure" onClick={() => onEdit(rowData)} >
						<i className="fa fa-pencil" ></i>
					</Button>
				)
			]
		},
        canSort: false,
        visible: true
	}
}

export const textColumn = (label, property, onMove, onHover, canMove?, canSort?, visible?) => {
	return {
		header: {
            label: label,
            transforms: [
                label => ({
                    onDragEnd: o => onMove(o)
                }),
                label => ({
                    onDragOver: o => onHover(o)
                })
            ]
		},
		property: property,
        canSort: canSort ? true : canSort,
        visible: visible ? true : visible,
        canRearrange: canMove ? true : canMove
	}
}


export const fssColumn = (label, property, onClickMethod, onMove, onHover, canMove, canSort, visible) => {
    return {
        header: {
            label: label,
            transforms: [
                label => ({
                    onDragEnd: o => onMove(o)
                }),
                label => ({
                    onDragOver: o => onHover(o)
                })
            ]
        },
        property: property,
        cell: {
            formatters: [
                (value, { rowData }) => {
                    if (value === true) {
                        return (
                            <Button onClick={() => { onClickMethod(rowData) }} className="btn btn-link">Results Found</Button> 
                        )
                    } else if (value === false) {
                        return (
                            <Button onClick={() => { onClickMethod(rowData) }} className="btn btn-link">Results Not Found</Button>
                        )
                    }
                }
            ]
        },
        canSort: canSort ? true : canSort,
        visible: visible ? true : visible,
        canRearrange: canMove ? true : canMove
    }
}

export const linkColumn = (label, property, onClickMethod, onMove, onHover, canMove, canSort, visible) => {
    return {
        header: {
            label: label,
            transforms: [
                label => ({
                    onDragEnd: o => onMove(o)
                }),
                label => ({
                    onDragOver: o => onHover(o)
                })
            ]
        },
        property: property,
        cell: {
            formatters: [
                (value, { rowData }) => {
                    return (
                        <Button onClick={() => { onClickMethod(rowData) }} className="btn btn-link">{value}</Button>
                    )
                }
            ]
        },
        canSort: canSort ? true : canSort,
        visible: visible ? true : visible,
        canRearrange: canMove ? true : canMove
    }
}

export const noWrapTextColumn = (label, property, canSort, visible) => {
	return {
		header: {
			label: label
		},
		property: property,
		cell: {
			formatters: [
				(value, { rowData }) => (
					<span style={{ whiteSpace: "nowrap" }}>{value}</span>
				)
			]
		},
        canSort: canSort ? true : canSort,
        visible: visible ? true : visible
	}
}

export const dateColumn = (label, property, canSort, visible) => {
	return {
		header: {
			label: label
		},
		property: property,
		cell: {
			formatters: [
				(value, { rowData }) => {
					return (
						value &&
						moment(value).format('MM/DD/YYYY')
					)
				}
			]
		},
        canSort: canSort ? true : canSort,
        visible: visible ? true : visible
	}
}

export const numberColumn = (label, property, canSort, visible) => {
	return {
		header: {
			label: label
		},
		property: property,
		cell: {
			formatters: [
				(value, { rowData }) => {
                    return value && numeral(value).format("(0,0.00[0000])");
				}
			]
        },
        canSort: canSort ? true : canSort,
        visible: visible ? true : visible
	}
}

const PreconfiguredPopover = (props) => {
    return (
        <Popover id="popover-p" title={props.title} style={{ ...props.style }} >
            {props.content}
        </Popover>
    );
}


export const lockStatusColumn = (label, property, onLockIconClick, lockTimeoutFn, showLockStatusIconFn, canSort, visible) => {
	
	return {
		header: {
			label: label
		},
		property: property,
        cell: {
            formatters: [
				(value, { rowData }) => {
					let showLockStatusIcon = showLockStatusIconFn(rowData);
					let overallContentClassName = (showLockStatusIcon) ? "show" : "hidden";
                    let isLocked = (rowData.frozenStatus == 1);

					let recordLockMinutes = isLocked ? rowData.frozenTime : lockTimeoutFn();
					
					let lockedByCurrentUserMsg = "You have locked this record.  Other users will not be able to edit the status.  If you do not change the status within " + recordLockMinutes + " minutes(s) from the time you locked it, the record will revert back to an editable state.";
					let lockedByOtherUserMsg = "The record is currently being viewed by another user and is in read-only status.  The record will be available after " + recordLockMinutes + " minutes(s) from the time it was locked if there is no change in status.";

					let lockedMessage = (rowData.frozenByCurrentUser) ? lockedByCurrentUserMsg : lockedByOtherUserMsg;					
					let notLockedMessage = "Click here to lock this record while you work on it.  Other users will still be able to view the content, but only you can change the status. Locked records will automatically unlock after " + recordLockMinutes + " minute(s) from the time you locked it if there is no change in status.";

                    let popoverContent = (isLocked) ? lockedMessage : notLockedMessage;

                    let lockedByText = (rowData.frozenByCurrentUser) ? "by you" : "other user";
					let lockedByTextCssClass = (rowData.frozenStatus) ? "" : "hidden";

                    let lockIcon = (isLocked) ? "fa fa-lock" : "fa fa-unlock";

					lockIcon = (rowData.isUpdating) ? "fa fa-circle-o-notch fa-spin" : lockIcon;

					const myPopover = <PreconfiguredPopover content={popoverContent} />;
					
					return (<div className={["text-center", overallContentClassName].join(" ")}>
								<div>
									<OverlayTrigger placement="right" trigger={["hover", "focus"]} overlay={myPopover}>
										<div>
											<button onClick={() => { onLockIconClick(rowData) }} className="btn btn-link"><i className={lockIcon} style={{ color: "black" }}></i></button>
											<div className={lockedByTextCssClass}>{ lockedByText }</div>
										</div>
									</OverlayTrigger>
								</div>
						</div>)
                 }
			]
		},
        canSort: canSort ? true : canSort,
        visible: visible ? true : visible,
        canRearrange: false
	}
}