import React from 'react';
import ReactTable from 'react-table-6';

export class GenericDataGrid extends React.Component  {

  constructor(props) {
    super(props);

    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  onPageSizeChange = (size, index) => {
    if (this.props.onPageSizeChange) {
      this.props.onPageSizeChange(size, index);
    }
  }

   onSortedChange = (column, additive) => {
      if (this.props.onSortedChange) {
      this.props.onSortedChange(column, additive);
    }
  }

   onPageChange = (index) => {
    if (this.props.onPageChange) {
      this.props.onPageChange(index);
    }
  }

   onResizedChange = (newResized, event) => {
    if (this.props.onResizedChange) {
      this.props.onResizedChange(newResized, event);
    }
  }

   render() {
    const { page, data, columns, pageSize, sorted, resized, resizable } = this.props;
    return (
      <ReactTable
        page={page}
        onPageChange={this.onPageChange}
        data={data}
        columns={columns}
            loading={false}
            defaultPageSize={10}
        minRows={0}
        pageSizeOptions={[10, 25, 50, 100]}
        noDataText=''
        className='-striped'
        pageSize={pageSize}
        onPageSizeChange={this.onPageSizeChange}
        onSortedChange={this.onSortedChange}
        onResizedChange={this.onResizedChange}
            sorted={sorted}
        //@ts-ignore
        resized={resized}
        resizable={resizable}
      />
    );
  }
}
