
const setStorage = (name, store) => {
  store.subscribe(() => {
    const state = store.getState()[name];
    localStorage.setItem(name, JSON.stringify(state));
  });
};

const getStorage = (name) => {
  const state = localStorage.getItem(name);
  if (state) {
    return JSON.parse(state);
  }
  return undefined;
};

export const getGridPersitance = (name) => {

  return {
    setStorage: (store) => setStorage(name, store),
    getStorage: () => getStorage(name)
  };
};