export const getNotificationColumns = (fileSchema, useNotificationLock, includeFss) => {
    let defaultColumns = [];

    let indexCounter = (() => {
        let count = -1;

        return {
            nextIndex: () => {
                count++;
                return count;
            },
            currentIndex: () => {
                return count;
            }
        }
    })();

    defaultColumns.push.apply(defaultColumns,
        [
            { inColumnPicker: false, property: "isSelected", label: "", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() },
        ]);

    defaultColumns.push.apply(defaultColumns, getLockColumns(useNotificationLock, indexCounter));   

    defaultColumns.push.apply(defaultColumns,
        [
            { inColumnPicker: false, property: "status", label: "Status", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() }
        ]);

    defaultColumns.push.apply(defaultColumns, getFssColumns(includeFss, indexCounter));

    defaultColumns.push.apply(defaultColumns, [
        { inColumnPicker: true, property: "courtCode", label: "Court", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() },
        { inColumnPicker: true, property: "chapter", label: "Chapter", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() },
        {
            inColumnPicker: false,
            property: "shortCaseNumber",
            label: "Case Number",
            index: 5,
            visible: true,
            showIndex: 5
        },
        { inColumnPicker: true, property: "dateFiled", label: "Filed", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() },
        { inColumnPicker: true, property: "fullName", label: "Name", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() },
        { inColumnPicker: true, property: "score", label: "Hit Score", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() },
        { inColumnPicker: true, property: "reportDateFormat", label: "Report Date", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() },
        {
            inColumnPicker: true,
            property: "scrubHitFields",
            label: "Hit Fields",
            index: 9,
            visible: true,
            showIndex: 9
        },
        { inColumnPicker: true, property: "queues", label: "Queues", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() },
        { inColumnPicker: true, property: "conUID", label: "ConUID", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() }
    ]);

    var fileSchemaStartIndex = indexCounter.nextIndex();

    fileSchema.map((x, i) => {
       /* if (x.validationType !== "") {*/
            defaultColumns.push({
                inColumnPicker: true,
                property: x.excelColumn.toLowerCase(),
                label: x.subscriberFieldName,
                index: i + fileSchemaStartIndex,
                visible: false,
                showIndex: 999
            });
       /* }*/
    });

    return defaultColumns;
};

const getFssColumns = (includeFss, indexCounter) => {
    let fssColumns = [];

    if (includeFss) {
        fssColumns = [
            { inColumnPicker: true, property: "fullSocialSearchResultsFound", label: "Full Social Search", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() }
        ];
    }

    return fssColumns;
}

const getLockColumns = (useNotificationLock, indexCounter) => {
    let lockColumns = [];
    if (useNotificationLock) {
        lockColumns = [
            { inColumnPicker: false, property: "frozenStatus", label: "Lock", index: indexCounter.nextIndex(), visible: true, showIndex: indexCounter.currentIndex() }
        ];
    }

    return lockColumns;
}