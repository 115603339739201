
const setStorage = (name, store) => {
    store.subscribe(() => {

        let state = store.getState()[name];

        let gridState = {
            columns: state.columns.sort(function (a, b) {
                return parseInt(a.showIndex) - parseInt(b.showIndex);
            }),
            paging: state.paging
        }


        localStorage.setItem(name, JSON.stringify(gridState))
    });
}

const getStorage = (name) => {
    var state = localStorage.getItem(name);
    if (state) return JSON.parse(state)
    return undefined;
}

export const getGridPersitance = (name) => {

    return {
        setStorage: (store) => setStorage(name, store),
        getStorage: () => getStorage(name)
    }
}