import * as React from "react";
import { Button, Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import { Form, Field, reduxForm, getFormSyncErrors, getFormValues, submit } from 'redux-form';
import * as Validator from 'validatorjs';
import { CheckboxFormGroup, InputFormGroup } from "../form"
import { post } from "../../../services/data-service";
import { toastr } from 'react-redux-toastr'


const rules = {
    name: 'required',
}



export const validate = values => {

    let validator = new Validator(values, rules);

    validator.setAttributeNames({
        name: 'Name'
    });
    validator.passes();
    return validator.errors.all();
};

const asyncValidate = (values) => {
    return post("/api/savedsearches/isNameUnique", {
        name: values.name
    }).then((isUnique) => {
        if (!isUnique) {
            throw { name: 'Duplicate Search Name' }
        }
    });
}


export class SaveSearchButtonForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showModal: false, filter: props.filter };
        this.save = this.save.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    save() {
        this.props.submit(FORM_NAME)
    }

    close() {
        this.setState({ showModal: false });
    }

    open() {
        this.setState({ showModal: true });
    }

    onSubmit(values) {

        let self = this;

        return post("/api/savedsearches/save", {
            definition: JSON.stringify(this.props.filter),
            name: values.name,
            isPublic: values.isPublic,
            isFavorite: values.isFavorite,
            searchDefinitionTypeId: this.props.searchDefinitionTypeId
        }).then(() => {
            toastr.success("Success!", "Search Saved", { timeOut: 30000, removeOnHoverTimeOut: 30000 });
            self.close();
            this.props.getSavedSearches(this.props.searchDefinitionTypeId)
        });
    }

    render() {
        return (
            <div>
                <Button id="btnSaveFilter" bsStyle="link" onClick={this.open}> <b className="fa fa-save"></b> Save Filter</Button>
                <Modal show={this.state.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Save Search</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SavedSearchForm {...this.props} onSubmit={this.onSubmit}  />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close}>Cancel</Button>
                        <Button bsStyle="primary" onClick={this.save}><b className="fa fa-save"></b> Save</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const SavedSearchForm = (props) => {
    let { handleSubmit, onSubmit } = props;
    return (
        <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Field name="name" component={InputFormGroup} label="Name *" />
            <Field name="isPublic" component={CheckboxFormGroup} label="Is Public" />
            <Field name="isFavorite" component={CheckboxFormGroup} label="Is Favorite" />
        </Form>
    )
}

const FORM_NAME = "SavedSearchForm";

const formErrors = getFormSyncErrors(FORM_NAME)
const formValues = getFormValues(FORM_NAME);

const mapStateToProps = (state, ownProps) => {

    return {
        formErrors: formErrors(state),
        formValues: formValues(state)
        //initialValues: state.page.initialFilter
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        submit: submit
    };
}

const reduxConnectedForm = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
    asyncValidate,
    asyncBlurFields: ['name']
})(SaveSearchButtonForm)

export const SaveSearchButton = connect(mapStateToProps, mapDispatchToProps)(reduxConnectedForm);
