import { get } from "../../../services/data-service";


const getSavedSearchesSuccess = (name, payload) => ({
    type: actionTypes.GET_SAVED_SEARCHES_SUCCESS,
    name: name,
    payload: payload
});


const toggleSearchFilter = (name) => ({
    type: actionTypes.SEARCH_FILTER_TOGGLE,
    name: name
});

const closeSearchFilter = (name) => ({
    type: actionTypes.SEARCH_FILTER_CLOSE,
    name: name
});

const updateFilterValues = (name, payload) => ({
    type: actionTypes.UPDATE_FILTER_VALUES,
    name: name,
    payload: payload
});


export const getSavedSearches = (name, searchDefinitionTypeId) => (dispatch, getState) => {
    get(`/api/savedsearches/list/${searchDefinitionTypeId}`)
        .then((r) => {
            dispatch(getSavedSearchesSuccess(name, r));
        });
};


export const searchFilterActions = (name) => {

    return {
        getSavedSearches : (searchDefinitionTypeId) => getSavedSearches(name, searchDefinitionTypeId),
        toggleSearchFilter: () => toggleSearchFilter(name),
        closeSearchFilter: () => closeSearchFilter(name),
        updateFilterValues: (payload) => updateFilterValues(name, payload)
    }
}


export const actionTypes = {
    SAVE_SEARCH_FILTER: "SAVE:SEARCH:FILTER",
    SAVE_SEARCH_FILTER_SUCCESS: "SAVE:SEARCH:FILTER:SUCCESS",
    GET_SAVED_SEARCHES: "GET:SAVED:SEARCHES",
    GET_SAVED_SEARCHES_SUCCESS: "GET:SAVED:SEARCHES:SUCCESS",
    SEARCH_FILTER_TOGGLE: "SEARCH:FILTER:TOGGLE",
    SEARCH_FILTER_CLOSE: "SEARCH:FILTER:CLOSE",
    UPDATE_FILTER_VALUES: "UPDATE:SEARCH:FILTER:VALUES",
    LOAD_SAVED_SEARCH: "LOAD:SAVED:SEARCH"
}
