import * as React from "react";
import { Modal } from "react-bootstrap";

export class ColumnPicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            columnPickerColumns: props.columnPickerColumns,
            catcherStyle: {}
        }
        
        this.updateColumnVisible = this.updateColumnVisible.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.columnPickerColumns.length > 0) {
            let x = 0;
        }
        this.setState({ columnPickerColumns: nextProps.columnPickerColumns });

    }

    updateColumnVisible(item) {
        this.props.updateColumnVisible(item);
    }

    render() {
        return (
            <ColumnPickerPanel
                {...this.props}
                catcherStyle={this.state.catcherStyle}
                updateColumnVisible={this.updateColumnVisible}
                isPickerCollapsed={this.props.isPickerCollapsed}
                onToggle={this.props.onToggle}
            />
        )
    }
}

class ColumnPickerPanel extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            style: { position: "absolute" },
        }
        this.updateColumnVisible = this.updateColumnVisible.bind(this);
    }

    updateColumnVisible(item) {
        this.props.updateColumnVisible(item);
    }

    render() {

        let { catcherStyle, onToggle, isPickerCollapsed, columnPickerColumns  } = this.props;
        let { style } = this.state

        const pickerCols = columnPickerColumns.filter((col) => col.inColumnPicker === true).sort(function (a, b) {
            return parseInt(a.index) - parseInt(b.index);
        }).map((col) =>
            <li key={col.index} onClick={() => this.updateColumnVisible(col)}>
                <input type="checkbox" onClick={() => this.updateColumnVisible(col)} checked={col.visible} onChange={() => this.updateColumnVisible(col)} />
                <span>{col.label}</span>
            </li>)
        
        return (
            <Modal show={!isPickerCollapsed} onHide={onToggle} className="center-modal">
                <ul className="ColVis_collection"
                    style={style}>
                    {pickerCols}
                </ul>
                <div className="ColVis_catcher" style={catcherStyle} onClick={onToggle}></div>
            </Modal>
        )
    }

}