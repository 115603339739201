import { post } from "../../../services/data-service";
import * as moment from "moment"

export const actionTypes = {
    DATA_GRID_GET_ITEMS: "DATA:GRID:GET:LIST",
    DATA_GRID_GET_ITEMS_SUCCESS: "DATA:GRID:GET:LIST:SUCCESS",
    DATA_GRID_PAGING_INFO_CHANGE: "DATA:GRID:CHANGE:PAGE",
    DATA_GRID_GET_ERROR: "DATA:GRID:GET:ERROR",
    DATA_GRID_UPDATE: "DATA:GRID:UPDATE",
    DATA_GRID_COLUMN_SHOWINDEX_UPDATE: "DATA:GRID:COLUMN:SHOWINDEX:UPDATE",
    DATA_GRID_ITEM_UPDATE: "DATA_GRID_ITEM:UPDATE",
    DATA_GRID_MULTIPLE_ITEM_UPDATE: "DATA:GRID:MULTIPLE_ITEM:UPDATE",
    DATA_GRID_CASE_DETAIL_PAGE: "DATA:GRID:CASE:DETAIL:PAGE",
    DATA_GRID_COLUMN_ORDER_UPDATE: "DATA:GRID:COLUMN:ORDER:UPDATE"
}


const getItemsSuccess = (name, payload) => ({
    type: actionTypes.DATA_GRID_GET_ITEMS_SUCCESS,
    name: name,
    items: payload.items,
    totalRecords: payload.totalRecords
});

const changePagingInfo = (name, payload) => ({
    type: actionTypes.DATA_GRID_PAGING_INFO_CHANGE,
    name: name,
    paging: payload
});

const updateColumnShowIndex = (name, payload) => ({
    type: actionTypes.DATA_GRID_COLUMN_SHOWINDEX_UPDATE,
    name: name,
    paging: payload
});

const updateItem = (name, payload) => ({
    type: actionTypes.DATA_GRID_ITEM_UPDATE,
    name: name,
    payload: payload
});

const updateMultipleItems = (name, payload) => ({
    type: actionTypes.DATA_GRID_MULTIPLE_ITEM_UPDATE,
    name: name,
    payload: payload
});

const changeColumnOrder = (name, payload) => ({
    type: actionTypes.DATA_GRID_COLUMN_ORDER_UPDATE,
    name: name,
    paging: payload
});

export const getDataGridActions = (name) => {

    return {
        changePagingInfo: (payload) => changePagingInfo(name, payload),
        updateColumnShowIndex: (payload) => updateColumnShowIndex(name, payload),
        getItems: (url, payload) => getItems(name, url, payload),
        updateItem: (payload) => updateItem(name, payload),
        updateMultipleItems: (payload) => updateMultipleItems(name, payload),
        changeColumnOrder: (payload) => changeColumnOrder(name, payload)
    }
}

export const getItems = (name, url, payload) => (dispatch, getState) => {
    if (payload.dateFiled) {
        payload.dateFiled = moment.utc(payload.dateFiled).local().format('MM/DD/YYYY');
    }


    post(url, payload).then((r) => {
        try {
            if (url == "/api/notification/filter") {
                let columns = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
                r.items.forEach(obj => {
                    columns.forEach(col => {
                        if (obj[col] && isNaN(obj[col]) && obj[col].indexOf(" ") > -1 && new Date(obj[col]).toString().indexOf("Invalid") == -1) {
                            obj[col] = obj[col].split(' ')[0].replaceAll("-", "/");
                        }
                    });
                });
            }
            dispatch(getItemsSuccess(name, r));
        } catch (err) {
            console.log(err);
            dispatch(getItemsSuccess(name, r));
        }
    });
};
