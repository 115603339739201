export const getInitialSort = (obj, position) => {
    let { orderByDirection, orderBy } = obj;
    let sort = {};
    sort[orderBy] = { position: position, direction: orderByDirection }
    return sort;
}

export const getSortValues = (sort) => {
    let prop = Object.keys(sort)[0];
    return {
        orderBy: prop,
        orderByDirection: sort[prop].direction
    }
}

export const getPageCount = (totalRecords, pageSize) => {

    let pages = pageSize === 0 ? 0 : Math.ceil(totalRecords / pageSize);
    return pages;
}

export const getPageSizeOverflow = (start, end, pageSize) => {

    if (!start || start === 0) {
        return 0;
    }

    if (start >= end) {
        start = end - pageSize;
    }

    start -= (start % pageSize);

    if (start < 0) {
        start = 0;
    }

    return start;
}

export const getPageIndex = (start, pageSize) => {

    let index = pageSize === 0 ? 0 : Math.ceil(start / pageSize);
    return index;
}

export const getPagingSettingsFromProps = (props) => {

    let pageSize = props.pageSize || 10;
    let records = props.totalRecords || 0;
    let start = getPageSizeOverflow(props.start, records, pageSize);
    let pageIndex = getPageIndex(start, pageSize)

    return {
        pageCount: getPageCount(records, pageSize),
        start: start,
        pageSize: pageSize,
        pageIndex: pageIndex,
        totalRecords: records,
        orderBy: props.orderBy,
        orderByDirection: props.orderByDirection
    }
}