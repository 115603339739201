import { useSelector } from 'react-redux';

const initialState = {};


export const reducer = (state = initialState, action) => {
    return state;
}


export const useHome = () => {
    const app = useSelector(state => state.home);
    return app;
}
